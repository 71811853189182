// src/pages/Subscription/PaymentMethodsPage.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Для использования useParams

import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_subscription";
import request from "../../utils/requests/requests";
import UserHeader from "../../components/block/header/header";
import * as UI from "../../components/ui";
import res from '../../resources/resources';
 /**
 * Component for displaying and managing payment methods.
 *
 * Fetches payment methods on component mount and stores them in state.
 * Provides a list of payment methods with radio buttons for selection.
 * Handles selection of a payment method and saves it to the server.
 * Provides a button for deleting a payment method.
 * Provides a button for canceling all payment methods.
 * Provides a button for navigating back.
 *
 * @returns {JSX.Element} JSX element representing the component.
 */
const PaymentMethods = () => {
    const navigate = useNavigate(); // Для навигации
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState(""); // Начальное значение пустое
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    // Fetch payment methods on component mount
    useEffect(() => {
        const fetchPaymentMethods = async () => {
            setLoading(true);
            setError("");
            try {
                const response = await request("GET", USER_ENDPOINTS.GET_PAYMENT_METHODS, false, true);
                if (response.status === "success") {
                    setPaymentMethods(response.data);
                    // Находим метод с is_active = 1 и устанавливаем его как выбранный
                    const activeMethod = response.data.find((method) => method.is_active === 1);
                    if (activeMethod) {
                        setSelectedMethod(activeMethod.payment_method_hash);
                    }
                } else {
                    setError(response.message || "Failed to load payment methods.");
                }
            } catch (err) {
                setError("An error occurred, please try again later.");
                console.error("Error:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchPaymentMethods();
    }, []);

    // Handle radio button selection// Handle radio button selection
    const handleSelectionChange = (hash) => {
        setSelectedMethod(hash);
    };

    // Save selected payment method
    // Save selected payment method
    const savePaymentMethod = async () => {
        setError("");
        setSuccessMessage("");
        // Проверка на пустое значение хэша
        if (!selectedMethod) {
            setError("Не выбран метод оплаты");
            return;
        }

        try {
            // Отправляем данные на сервер
            const response = await request(
                "POST",
                USER_ENDPOINTS.POST_NEW_PAYMENT_METHOD, 
                { payment_hash: selectedMethod }, // Передаем выбранный хэш
                false, 
                true
            );

            if (response.status === "success") {
                setSuccessMessage("Обновили метод оплаты.");
            } else {
                setError(response.message || "Ошибка обновления метода оплаты.");
            }
        } catch (err) {
            setError("Что-то пошло не так, попробуйте ещё раз.");
            console.error("Error:", err);
        }
    };

    // Delete payment method
    const deletePaymentMethod = async (paymentHash) => {
        setError("");
        setSuccessMessage("");
        // Проверка на пустое значение хэша
        if (!paymentHash) {
            setError("Не выбран метод оплаты");
            return;
        }
    
        try {
            // Отправляем запрос на удаление метода оплаты
            const response = await request(
                "POST", 
                USER_ENDPOINTS.POST_PAYMENT_METHOD_TO_DELETE, 
                { payment_hash: paymentHash },  // Передаем хэш метода
                false, 
                true
            );
    
            if (response.status === "success") {
                setSuccessMessage("Метод оплаты удален");
                // Удаляем метод из списка
                setPaymentMethods((prevMethods) =>
                    prevMethods.filter((method) => method.payment_method_hash !== paymentHash)
                );
            } else {
                setError(response.message || "Ошибка удаления метода оплаты.");
            }
        } catch (err) {
            setError("Что-то пошло не так, попробуйте ещё раз.");
        }
    };

    // Cancel all payment methods
    const cancelAllPaymentMethods = async () => {
        setError("");
        setSuccessMessage("");
        try {
            // Отправляем запрос на обновление всех методов оплаты
            const response = await request("POST", USER_ENDPOINTS.CANCEL_ALL, false, true);
            if (response.status === "success") {
                setSuccessMessage("Подписка отменена. Текущая подписка будет доступна до окончания срока действия");
                
                // Обновляем состояние, чтобы пометить все методы как неактивные (is_active = 0)
                setPaymentMethods((prevMethods) =>
                    prevMethods.map((method) => {
                        if (method.payment_method_hash === selectedMethod) {
                            // Если текущий выбранный метод стал неактивным, сбрасываем выбранный метод
                            setSelectedMethod(""); // Сбрасываем выбор, если активный метод был отменен
                        }
                        return {
                            ...method,
                            is_active: 0, // Устанавливаем статус неактивности
                        };
                    })
                );
            } else {
                setError(response.message || "Ошибка отмены подписки.");
            }
        } catch (err) {
            setError("Что-то пошло не так, попробуйте ещё раз.");
        }
    };

    return (
        <div className="container">
            <UserHeader />
            <div className="navigate">
                <div className="go-back">
                    <UI.Button type="secondary" icon="ic_24_arrow_left" onClick={() => navigate(-1)} className="back-btn" />
                    <h3>Методы оплаты</h3>
                </div>
                {/* <div className="actions">
                    <UI.Button icon="ic_24_coupon" type="secondary" to="/dashboard/subscription/usePromocode" className="payment-btn" />
                    <UI.Button icon="ic_24_bill" type="secondary" to="/dashboard/subscription/history" className="payment-btn" />
                </div> */}
            </div>
            {loading && <div className="loading">🤔</div>}
            {error &&  <UI.ToastNotification status="error" message={error} />}
            {successMessage &&  <UI.ToastNotification status="success" message={successMessage} />}     
            {paymentMethods.length > 0 ? (    
                    <div className="payment-methods-list">
                        {paymentMethods.map((method) => (
                            <div
                                key={method.payment_method_hash}
                                className={`payment-method ${selectedMethod === method.payment_method_hash ? "active" : ""}`}
                                onClick={() => handleSelectionChange(method.payment_method_hash)}
                            >
                            <div className="info">    
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    value={method.payment_method_hash}
                                    checked={selectedMethod === method.payment_method_hash}
                                    onChange={() => handleSelectionChange(method.payment_method_hash)}
                                />
                                <img src={res.icons[`ic_card_${method.type}`]} alt={method.title} />
                                <span>
                                    {method.title}
                                </span>
                            </div>
                                <UI.Button
                                    type="secondary"
                                    icon={"ic_24_trash"}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Останавливаем клик по карточке
                                        deletePaymentMethod(method.payment_method_hash);
                                    }}
                                />
                            </div>
                        ))}
                        <div className="button-container">
                            <UI.Button width="100%" type="primary" text="Сохранить" onClick={savePaymentMethod} />
                            <UI.Button width="100%" type ="secondary" text="Отменить подписку" onClick={cancelAllPaymentMethods} />                   
                        </div>

                    </div>
            ) : (
                <p>No payment methods found.</p>
            )}
        </div>
    );
};

export default PaymentMethods;