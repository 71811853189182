import React from 'react';
import { Link } from 'react-router-dom';  // Если используется react-router для маршрутизации

const NotFoundPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>404 - Страница не найдена</h1>
      <p style={styles.message}>
        К сожалению, мы не смогли найти страницу, которую вы искали.
      </p>
      <Link to="/" style={styles.link}>Вернуться на главную</Link>
    </div>
  );
};

// Стилизация страницы
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f4f4f4',
  },
  header: {
    fontSize: '2.5rem',
    color: '#ff6347',
  },
  message: {
    fontSize: '1.2rem',
    color: '#333',
    marginBottom: '20px',
  },
  link: {
    textDecoration: 'none',
    padding: '10px 20px',
    backgroundColor: '#ff6347',
    color: 'white',
    borderRadius: '5px',
    fontSize: '1rem',
  },
};

export default NotFoundPage;