// src/pages/viewer/GetItemsViewer.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Для работы с параметрами маршрута

import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_spaces";
import request from "../../utils/requests/requests"; // Для выполнения запросов

import { useGlobalContext } from "../../utils/context/globalStates";

import * as UI from "../../components/ui";

const GetItemsViewer = () => {
    const { boxInfo, setBoxInfo } = useGlobalContext(); // Получаем глобальные состояния
    const { spaceHash, boxHash, itemHash } = useParams(); // Получаем spaceHash, boxHash и itemHash из параметров маршрута
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBoxData = async () => {
            setLoading(true);
            setError("");

            try {
                // Строим URL запроса в зависимости от доступных параметров
                let url = USER_ENDPOINTS.GET_ITEMS_VIEWER(spaceHash, boxHash, itemHash);

                // Выполняем запрос для получения данных
                const data = await request("GET", url, false, true); // Запрос с Bearer token

                if (data.status === "success") {
                    // Если есть конкретный itemHash
                    if (itemHash) {
                        const item = data.items.find((i) => i.item_hash === itemHash);
                        if (item) {
                            setBoxInfo(item); // Если элемент найден, сохраняем его
                        } else {
                            setError("Item not found.");
                        }
                    } else if (boxHash) {
                        // Если передан boxHash, ищем все элементы в коробке
                        const validItems = data.items.filter((item) => item.box_hash === boxHash);
                        if (validItems.length > 0) {
                            setBoxInfo({ box_name: data.box_name, box_description: data.box_description, items: validItems }); // Сохраняем название коробки и элементы
                        } else {
                            setError("No items found in the box.");
                        }
                    } else {
                        // Если ни itemHash, ни boxHash не переданы, показываем ошибку
                        setError("No item or box found.");
                    }
                } else {
                    setError(data.message || "Failed to load item");
                }
            } catch (err) {
                setError("An error occurred, please try again later");
                console.error("Error:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchBoxData();
    }, [spaceHash, boxHash, itemHash]); // Срабатывает при изменении spaceHash, boxHash или itemHash

    return (
        <div className="container">
            <title>{ boxInfo && boxInfo.box_name ? boxInfo.box_name : "🤔"}</title>
            <UI.Button text="Назад" to={`/viewer/${spaceHash}`} className="go-back-btn" />

            {loading && <div className="loading">Loading...</div>}
            {error && <div className="error">{error}</div>}
            {boxInfo && (
                <div className="box-info">
                    {boxInfo.box_name && <h1>{boxInfo.box_name}</h1>}
                    {boxInfo.box_description && <h2>{boxInfo.box_description}</h2>}
                    {Array.isArray(boxInfo.items) ? (
                        <div className="box-items">
                            <h2>Items in the box:</h2>
                            <ul>
                                {boxInfo.items.length === 0 ? (
                                    <p>No items with boxes found.</p>
                                ) : (
                                    boxInfo.items.map((item) => (
                                        <li key={item.item_hash} className="box-item">
                                            <h3>{item.name}</h3>
                                            <p>{item.description}</p>
                                            <p>Created at: {item.created_at}</p>
                                            <img className="box-item-img" src={`https://boxyfox.ru/uploads/${item.photo_url}`} alt={item.name} />
                                            <p>{item.has_deleted ? "This item has been deleted" : "This item is active"}</p>
                                        </li>
                                    ))
                                )}
                            </ul>
                        </div>
                    ) : (
                        <div className="box-single">
                            <h1>{boxInfo.name}</h1>
                            <p>{boxInfo.description}</p>
                            <p>Created at: {boxInfo.created_at}</p>
                            <p>{boxInfo.has_deleted ? "This item has been deleted" : "This item is active"}</p>
                        </div>
                    )}
                </div>
            )}
        </div>
        
    );
};

export default GetItemsViewer;