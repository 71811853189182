import React from 'react';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>О нас</h1>
        <p style={styles.subtitle}>Познакомьтесь с нашей командой и нашим сервисом!</p>
      </header>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Наша миссия</h2>
        <p>
          Мы стремимся предоставить пользователям уникальные и простые в использовании решения для
          управления своими вещами. Наша цель - помочь вам организовать вашу жизнь, избавиться от
          хаоса и всегда иметь под рукой нужные вещи.
        </p>

        <h2 style={styles.sectionTitle}>Наша команда</h2>
        <p>
          Мы — команда профессионалов, которые увлечены тем, чтобы создавать продукты, которые
          решают реальные проблемы. Наша команда состоит из специалистов по разработке, дизайну,
          маркетингу и поддержке пользователей.
        </p>

        <h2 style={styles.sectionTitle}>Наши ценности</h2>
        <ul style={styles.list}>
          <li>Простота и удобство для пользователей</li>
          <li>Инновации и постоянное развитие</li>
          <li>Прозрачность и честность в общении с пользователями</li>
          <li>Ответственность за качество и безопасность</li>
        </ul>

        <h2 style={styles.sectionTitle}>Почему мы?</h2>
        <p>
          Мы уверены, что наш сервис поможет вам эффективно управлять вашими вещами и сэкономит
          время на поиске нужных предметов. Мы постоянно улучшаем наш продукт, чтобы предоставить
          вам лучшие инструменты для организации и хранения.
        </p>
      </section>

      <footer style={styles.footer}>
        <Link to="/" style={styles.button}>На главную</Link>
      </footer>
    </div>
  );
};

// Стилизация страницы
const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    color: '#333',
    margin: 0,
    padding: 0,
    textAlign: 'center',
  },
  header: {
    backgroundColor: '#ff6347',
    padding: '50px 0',
    color: 'white',
  },
  title: {
    fontSize: '3rem',
    margin: '0',
  },
  subtitle: {
    fontSize: '1.5rem',
    marginTop: '10px',
  },
  section: {
    padding: '50px 20px',
    backgroundColor: '#fff',
  },
  sectionTitle: {
    fontSize: '2rem',
    marginBottom: '20px',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    textAlign: 'left',
    marginLeft: '20px',
  },
  footer: {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    fontSize: '0.9rem',
  },
  button: {
    padding: '15px 30px',
    backgroundColor: '#ffffff',
    color: '#ff6347',
    fontSize: '1.2rem',
    borderRadius: '5px',
    textDecoration: 'none',
  },
};

export default AboutPage;