import React from 'react';

const Checkbox = ({ id, label, checked, onChange, errorMessage }) => {
  return (
    <div style={{ marginBottom: "15px" }}>
      <label htmlFor={id} style={{ display: "block", marginBottom: "5px" }}>
        {label}
      </label>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        style={{
          width: "20px",
          height: "20px",
          marginRight: "10px",
        }}
      />
      {errorMessage && (
        <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default Checkbox;