import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./routes";
import AuthRedirect from '../requests/AuthRedirect'; // Импортируем компонент проверки авторизации

const AppRouter = () => {
    return (
        <Router>
            <AuthRedirect /> {/* Добавляем компонент для проверки авторизации */}
            <Routes>
                {routes.map(({ path, component: Component }, index) => (
                    <Route key={index} path={path} element={<Component />} />
                ))}
            </Routes>
        </Router>
    );
};

export default AppRouter;

