// src/pages/UserAuth/RecoverPage.js
import React, { useState } from "react";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_user";
import request from "../../utils/requests/requests";
import * as UI from "../../components/ui";
import { SmartCaptcha } from '@yandex/smart-captcha';
import res from "../../resources/resources";
/**
 * RecoverPage component provides a user interface for password recovery.
 * It includes an input field for the user's email with basic validation.
 * On form submission, it sends a password recovery request to the server.
 * Displays error messages when needed. Includes navigation buttons to login 
 * and home pages.
 *
 * @returns {React.ReactElement} The JSX element to be rendered.
 */
const RecoverPage = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(''); // Для хранения token с капчи
    const [captchaKey, setCaptchaKey] = useState(Date.now());  // Ключ для перемонтирования капчи

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Простая валидация
        if (!email) {
            setError("Введите email");
            return;
        }

        // Регулярное выражение для email
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(email)) {
            setError("Некорректный email");
            return;
        }

        if (!token) {
            setError("Пожалуйста, пройдите капчу");
            return;
        }

        setError("");
        setLoading(true);

        try {
            const data = await request("POST", USER_ENDPOINTS.POST_RECOVER_USER, { email, captcha_token: token }, false, true);

            if (data.success) {
                console.log("Письмо отправлено:", data);
            } else {
                setError(data.message || "Ошибка отправки письма");
                setCaptchaKey(Date.now()); // Перемонтируем капчу в случае ошибки
            }
        } catch (err) {
            setError("Что-то пошло не так, попробуйте ещё раз");
            setCaptchaKey(Date.now()); // Перемонтируем капчу в случае ошибки
            console.error("Error:", err); // Дополнительное логирование ошибки для отладки
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="entry-page">
            <title>Восстановление пароля</title>
            {error && <UI.ToastNotification status="error" message={error} />}
            
            <img className="image" src={res.illustrations['ill_fox_in_a_box']} alt="recover password" />

            <form className="entry-page__container" onSubmit={handleSubmit}>
                <h1 className="title">Восстановить пароль</h1>
                <p className="subtitle">Введите свой email, чтобы восстановить доступ</p>
                
                <UI.Input 
                    type="email" 
                    label="Email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                />

                {/* CAPTCHA */}
                <SmartCaptcha 
                    sitekey="ysc1_KvpV7l8Auc3wNPbW859VDH3y2qnvrQkETA5RnOSI43c3bb09" 
                    onSuccess={setToken}
                    key={captchaKey}  // Ключ для перерендеринга капчи
                />

                <div className="button-container">
                    <div className="base-buttons">
                        <UI.Button 
                            width="252" 
                            type="secondary" 
                            text="Войти" 
                            to="/login" 
                        />
                        <UI.Button 
                            width="252" 
                            text={loading ? "Загрузка..." : "Восстановить пароль"} 
                            onClick={handleSubmit} 
                        />
                    </div>
                    <div className="addictional-buttons">
                        <UI.Button 
                            width="252" 
                            type="secondary" 
                            text="Создать аккаунт" 
                            to="/registration" 
                        />

                    </div>
                </div>
            </form>
        </div>
    );
};

export default RecoverPage;