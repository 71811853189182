// src/utils/misc/handleDelete.js
import request from "../../utils/requests/requests"; // или импортируйте вашу функцию запроса

const handleDelete = async ({
  e,
  endpoint,
  params,
  setError,
  setLoading,
  successRoute,
  navigate,
}) => {
  setError(""); // очищаем ошибку
  const userConfirmed = window.confirm("Вы уверены, что хотите удалить? Это действие нельзя будет отменить.");
  if (!userConfirmed) return false;

  setLoading(true); // включаем загрузку

  try {
      const response = await request("POST", endpoint, params, false, true);

      if (response && response.status === "success") {
          if (successRoute && navigate) {
              navigate(successRoute); // перенаправление после успешного удаления
          }
          return true; // Возвращаем `true` для успешного удаления
      } else {
          setError(response.message || "Не удалось удалить.");
          return false; // Возвращаем `false` в случае ошибки
      }
  } catch (err) {
      setError("Произошла ошибка. Пожалуйста, попробуйте позже.");
      console.error("Ошибка:", err);
      return false; // Возвращаем `false` при ошибке
  } finally {
      setLoading(false); // выключаем загрузку
  }
};

export default handleDelete;