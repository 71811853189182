// src/utils/requests/e_subscription.js

// Эндпоинты для запросов пользователя
export const ENDPOINTS = {
    GET_MY_SUBSCRIPTION: "/subscription/status_subscription.php",
    GET_HISTORY_PAYMENTS: "/subscription/payment_history.php", 
    GET_PROCEED_PAYMENT: (tier) => `/subscription/proceed_payment.php?tier=${tier}`,

    GET_PAYMENT_METHODS: "/subscription/get_payment_methods.php", 
    POST_NEW_PAYMENT_METHOD: "/subscription/change_payment_method.php", 
    POST_PAYMENT_METHOD_TO_DELETE: "/subscription/delete_payment_method.php", 
    CANCEL_ALL: "/subscription/cancel_all_payment_methods.php", 
    
    GET_PROMOCODE: "/subscription/show_promocode.php",
    POST_PROMOCODE: "/subscription/use_promocode.php",
    POST_DELETE_PROMOCODE: "/subscription/delete_promocode.php",
};