import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import request from "../../utils/requests/requests";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_spaces";
import { useGlobalContext } from "../../utils/context/globalStates";

import UserHeader from "../../components/block/header/header";
import convertToClientTimeZone from "../../utils/time/convertToUserTz";
import * as UI from "../../components/ui";

import handleDelete from "../../utils/misc/handleDelete";
import ItemCard from "../../components/elements/card/itemCard";

const GetItems = () => {
    const { boxInfo, setBoxInfo } = useGlobalContext();
    const { spaceHash, boxHash, itemHash } = useParams();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [openMenuId, setOpenMenuId] = useState(null);
    const [isDropdownOpen, setDropdownOpen] = useState(false); // Состояние для дропдауна
    const navigate = useNavigate();

    const updateItems = (itemHashToDelete) => {
        setBoxInfo((prevBoxInfo) => ({
            ...prevBoxInfo,
            items: prevBoxInfo.items.filter((item) => item.item_hash !== itemHashToDelete),
        }));
    };

    useEffect(() => {
        const fetchBoxData = async () => {
            setLoading(true);
            setError("");

            try {
                const data = await request("GET", USER_ENDPOINTS.GET_ITEMS(spaceHash, boxHash, itemHash), false, true);

                if (data.status === "success") {
                    if (itemHash) {
                        const item = data.items.find((i) => i.item_hash === itemHash);
                        setBoxInfo(item || {});
                    } else if (boxHash) {
                        const validItems = data.items.filter((item) => item.box_hash === boxHash);
                        setBoxInfo({ ...data, items: validItems });
                    } else {
                        setError("Invalid data received.");
                    }
                } else {
                    setError(data.message || "Failed to load data");
                }
            } catch (err) {
                setError("An error occurred, please try again later");
                console.error("Error:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchBoxData();
    }, [spaceHash, boxHash, itemHash]);

    return (
        <div className="container">
            <title>{boxInfo && boxInfo.box_name ? boxInfo.box_name : "🤔"}</title>
            <UserHeader />
            <div className="navigate">
                <div className="go-back">
                    
                    <UI.Button icon="ic_24_arrow_left" type="secondary" to={`/dashboard/${spaceHash}`} />
                    <div className="name-description">
                        {boxInfo ? <h3>{boxInfo.box_name}</h3> : <h3>🤔</h3>}
                        {boxInfo.box_description && <div>{boxInfo.box_description}</div>}
                    </div>
                </div>
                <div className="actions">
                    <UI.Button
                        icon="ic_24_dots_vertical"
                        type="secondary"
                        onClick={() => setDropdownOpen((prev) => !prev)}
                    />

                    {/* Дропдаун с экшенами */}
                    {isDropdownOpen && (
                        <UI.DropdownMenu
                            isOpen={isDropdownOpen}
                            onClose={() => setDropdownOpen(false)}
                            options={[
                                {
                                    icon: "ic_24_qr",
                                    text: "Показать QR",
                                    onClick: () => navigate(`/dashboard/${spaceHash}/${boxHash}/showBoxQR`),
                                },
                                {
                                    icon: "ic_24_pen",
                                    text: "Редактировать",
                                    onClick: () => {
                                        navigate(`/dashboard/${spaceHash}/${boxHash}/editBox?fromBox=1`, {
                                            state: {
                                                boxName: boxInfo.box_name,
                                                boxDescription: boxInfo.box_description,
                                                isPrivate: boxInfo.is_private === 1 || boxInfo.is_private === true,
                                            },
                                        });
                                    },
                                },
                                {
                                    icon: "ic_24_trash",
                                    text: "Удалить",
                                    onClick: (e) =>
                                        handleDelete({
                                            e,
                                            endpoint: USER_ENDPOINTS.POST_DELETE_BOX,
                                            params: { box_hash: boxHash },
                                            successRoute: `/dashboard/${spaceHash}`,
                                            updateState: setBoxInfo,
                                            confirmMessage:
                                                "Вы уверены, что хотите удалить эту коробку? Это действие нельзя будет отменить.",
                                            setError: setError,
                                            setLoading,
                                            navigate,
                                        }),
                                },
                            ]}
                        />
                    )}
                    <UI.Button text="Добавить предмет" to={`/dashboard/${spaceHash}/${boxHash}/createItem`} />

                </div>
            </div>

            {error && <div className="error">{error}</div>}
            {boxInfo && (
                <div className="box-info">
                    {Array.isArray(boxInfo.items) && boxInfo.items.length > 0 ? (
                        <div className="items-list">
                            {boxInfo.items.map((item) => (
                                <ItemCard
                                    key={item.item_hash}
                                    item={item}
                                    fromBox={1}
                                    openMenuId={openMenuId}
                                    setOpenMenuId={setOpenMenuId}
                                    setError={setError}
                                    setLoading={setLoading}
                                    updateState={updateItems}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="empty-box-message">
                            <p>В этой коробке пока ничего нет.</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default GetItems;