import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_spaces";
import request from "../../utils/requests/requests";
import * as UI from "../../components/ui"; // UI компоненты, включая Input, TextArea, Button, CheckBox
import UserHeader from "../../components/block/header/header";
 /**
 * EditExistingBox - компонент для редактирования существующей коробки
 * 
 * Отображает форму с полем ввода имени коробки, текстовым полем для описания коробки,
 * а также чекбокс для приватности коробки, и две кнопки: "Сохранить изменения" и "Назад".
 * 
 * При отправке формы компонент отправляет POST-запрос на сервер с именем коробки, описанием и статусом приватности.
 * Если запрос успешен, компонент перенаправляет пользователя на страницу коробки с полученным box_hash.
 * Если запрос неудачен, компонент отображает сообщение об ошибке.
 */
const EditExistingBox = () => {
  const { spaceHash, boxHash } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { boxName, boxDescription, isPrivate } = location.state || {};

  const [name, setName] = useState(boxName || "");
  const [description, setDescription] = useState(boxDescription || "");
  const [isPrivateState, setIsPrivateState] = useState(isPrivate || false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const fromBox = searchParams.get("fromBox");

  // Функция для возврата на предыдущую страницу
  const goBack = (e) => {
    e.preventDefault();  // Предотвращаем действие по умолчанию (например, submit)

    if (fromBox === "1") {
      navigate(`/dashboard/${spaceHash}/${boxHash}`);
    } else {
      navigate(`/dashboard/${spaceHash}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      setError("Введите название коробки");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const response = await request(
        "POST",
        USER_ENDPOINTS.POST_EDIT_BOX,
        {
          space_hash: spaceHash,
          box_hash: boxHash,
          name,
          description,
          is_private: isPrivateState,
        },
        false,
        true
      );

      if (response.status === "success") {
        navigate(`/dashboard/${spaceHash}/${boxHash}`);
      } else {
        setError(response.message || "Ошибка обновления коробки");
      }
    } catch (err) {
      setError("Что-то пошло не так, попробуйте позже");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <UserHeader />
      {error &&  <UI.ToastNotification status="error" message={error} />}
      <div className="navigate">
        <div className="go-back">
          <UI.Button
            icon="ic_24_arrow_left"
            type="secondary"
            onClick={goBack} // Используем goBack для возврата
            className="go-back-btn"
          />
          <h3>Редактировать коробку</h3>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="wrapper">
        <form className="form" onSubmit={handleSubmit}>
          <UI.Input
            id="boxName"
            placeholder="Введите название"
            label="Название коробки"
            value={name}
            onChange={(e) => setName(e.target.value)}
            errorMessage={error && "Название коробки обязательно"}
          />
          
          <UI.TextArea
            id="boxDescription"
            label="Описание коробки"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Описание..."
            errorMessage={error && "Описание коробки обязательно"}
          />
          
          <UI.CheckBox
            id="isPrivate"
            label="Сделать коробку приватной?"
            checked={isPrivateState}
            onChange={(e) => setIsPrivateState(e.target.checked)}
          />

          <div className="button-container">           
            <UI.Button
              width="100%"
              text={loading ? "Загрузка..." : "Сохранить"}
              onClick={handleSubmit}
            />
            <UI.Button
              width="100%"
              type="secondary"
              text="Назад"
              onClick={goBack} // Передаем функцию goBack в onClick
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditExistingBox;