import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as UI from "../../ui";
import { ReactSVG } from "react-svg";
import handleDelete from "../../../utils/misc/handleDelete";
import { ENDPOINTS as USER_ENDPOINTS } from "../../../utils/requests/e_spaces";
import res from "../../../resources/resources";

const ItemCard = ({ item, openMenuId, fromBox = 0, setOpenMenuId, setError, setLoading, updateState }) => {
    const navigate = useNavigate();
    const [isDeleted, setIsDeleted] = useState(false);

    // Переключение меню
    const toggleMenu = (e, id) => {
        e.stopPropagation();
        setOpenMenuId((prevId) => (prevId === id ? null : id));
    };

    // Закрытие меню при клике вне карточки
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (!e.target.closest(".item-card")) {
                setOpenMenuId(null);
            }
        };

        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [setOpenMenuId]);

    // Удаление предмета
    const handleItemDelete = async (e) => {
        const result = await handleDelete({
            e,
            endpoint: USER_ENDPOINTS.POST_DELETE_ITEM,
            params: { item_hash: item.item_hash },
            setError,
            setLoading,
            successRoute: `/dashboard/${item.spaceHash}/${item.boxHash}`,
        });

        if (result) {
            setIsDeleted(true);
            updateState((prevItems) => prevItems.filter((el) => el.item_hash !== item.item_hash));
        }
    };

    if (isDeleted) return null;

    return (
        <div className="item-card">
            <img
                className="item-card__img"
                src={`https://boxyfox.ru/uploads/${item.photo_url}`}
                alt={item.name}
            />
            <div className="item-card__info">
                <div className="item-card__header">
                    <ReactSVG src={res.icons["ic_24_label"]} className="item-card__icon" />
                    <div className="item-card__header-name">{item.name}</div>
                    <UI.Button
                        icon="ic_24_dots_vertical"
                        type="secondary"
                        size="medium"
                        className="item-card__header-btn"
                        onClick={(e) => toggleMenu(e, item.item_hash)}
                    />
                </div>
                <div className="item-card__description">{item.description}</div>
            </div>

            {/* Универсальный дропдаун */}
            <UI.DropdownMenu
                isOpen={openMenuId === item.item_hash}
                onClose={() => setOpenMenuId(null)}
                options={[
                    {
                        icon: "ic_24_pen",
                        text: "Редактировать",
                        onClick: () =>
                            navigate(`/dashboard/${item.space_hash}/${item.box_hash}/${item.item_hash}/editItem?fromBox=${fromBox}`, {
                                state: {
                                    name: item.name,
                                    description: item.description,
                                    isPrivate: item.is_private === 1 || item.is_private === true,
                                    imageUrl: item.photo_url,
                                },
                            }),
                    },
                    {
                        icon: "ic_24_trash",
                        text: "Удалить",
                        onClick: handleItemDelete,
                    },
                ]}
            />
        </div>
    );
};

export default ItemCard;