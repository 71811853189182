import React, { memo } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import res from "../../../resources/resources";

const ListButton = memo(({ 
    text, 
    onClick, 
    state, 
    disabled = false, 
    to, 
    type = "primary", 
    size = "medium", 
    icon 
}) => {
    // Динамическое формирование классов
    const buttonClass = `buttonList buttonList--${type} buttonList--${size}`;

    // Контент кнопки, общий для обеих версий
    const buttonContent = (
        <div className="buttonList__content">
            {icon && <ReactSVG src={res.icons[icon]}/>}
            {text && <span className="buttonList__text">{text}</span>}
        </div>
    );

    const renderButton = (
        <button
            className={buttonClass}
            onClick={onClick}
            disabled={disabled}
        >
            {buttonContent}
        </button>
    );

    // Если есть 'to', рендерим как ссылку
    return to ? (
        <Link to={to} state={state} className="buttonList__link">
            {renderButton}
        </Link>
    ) : (
        renderButton
    );
});

export default ListButton;