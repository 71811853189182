// src/pages/UserAuth/CheckVerification.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_user";
import request from "../../utils/requests/requests";

import * as UI from "../../components/ui";

/**
 * Page for checking email verification.
 *
 * It receives verification hash and user id via URL parameters, sends a GET request to the server and
 * displays a success message if the verification is successful, or an error message if it fails.
 *
 * If the verification is successful, it displays a button to go to the login page and a button to resend
 * the verification email. If the verification fails, it displays only the button to resend the verification
 * email.
 *
 * @returns {React.ReactElement} The JSX element to be rendered.
 */
const CheckVerificationPage = () => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Получаем параметры из URL
        const params = new URLSearchParams(location.search);
        const hash = params.get("hash");
        const userId = params.get("user_id");

        if (!hash || !userId) {
            setError("Invalid or missing parameters");
            setLoading(false);
            return;
        }

        const verifyEmail = async () => {
            setError("");
            setLoading(true);

            try {
                // Отправка GET-запроса на сервер
                const data = await request("GET", USER_ENDPOINTS.GET_USER_VERIFICATION(hash, userId), null, false);

                if (data.success) {
                    setSuccessMessage("Your email has been successfully verified!");
                } else {
                    setError(data.message || "Verification failed");
                }
            } catch (err) {
                setError("An error occurred, please try again later");
                console.error("Error:", err); // Логирование для отладки
            } finally {
                setLoading(false);
            }
        };

        verifyEmail();
    }, [location.search]);

    return (
        <div style={{ maxWidth: "400px", margin: "0 auto", padding: "20px" }}>
            <h2>Check Verification</h2>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    {successMessage && <UI.ToastNotification status="success" message={successMessage} hasCloseButton={true} />
                }
                    {error && <UI.ToastNotification status="error" message={error} hasCloseButton={true} />}
                    <UI.Button text="Go to Login" to="/login" />
                    <UI.Button text="Resend Verification" to="/recover" />
                </>
            )}
        </div>
    );
};

export default CheckVerificationPage;