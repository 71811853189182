import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import request from './requests'; // Для выполнения запросов
import { ENDPOINTS as USER_ENDPOINTS } from './e_user'; // Эндпоинт для проверки авторизации

const AuthRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Список путей, которые не требуют авторизации
  const excludedPaths = [
    // '/',               // Главная страница
    '/rules',          // Страница с правилами
    '/privacy',        // Страница с политикой конфиденциальности
    '/about',          // Страница о нас
    '/credentials',    // Страница с нашими данными
    '/viewer',         // Все пути, начинающиеся с /viewer
  ];

  useEffect(() => {
    const sessionToken = localStorage.getItem('session_token');

    // Пропускаем страницы из списка исключений
    if (excludedPaths.some(path => location.pathname.startsWith(path))) {
      return; // Если путь начинается с одного из исключений, пропускаем проверку
    }

    // Если находимся на /dashboard
    if (location.pathname.startsWith('/dashboard')) {
      // Если токена нет, редиректим на /login
      if (!sessionToken) {
        navigate('/login');
        return;
      }

      // Если токен есть, проверяем его через API
      const checkAuth = async () => {
        try {
          const response = await request('POST', USER_ENDPOINTS.POST_CHECK_AUTH_REDIRECT, false, true);
        
          if (response.auth === false) {
            navigate('/login'); // Если авторизация не прошла, редиректим на /login
          }
        } catch (error) {
          navigate('/login'); // В случае ошибки редиректим на /login
        }
      };

      checkAuth();
    }

    // Если находимся на авторизационных страницах (login, registration, recover и т.д.)
    if (['/login', '/registration', '/recover', '/check-verification'].includes(location.pathname)) {
      // Если токен есть, проверяем его через API
      if (sessionToken) {
        const checkAuth = async () => {
          try {
            const response = await request('POST', USER_ENDPOINTS.POST_CHECK_AUTH_REDIRECT, false, true);
         
            if (response.auth === true) {
              navigate('/dashboard'); // Если авторизация успешна, редиректим на /dashboard
            }
          } catch (error) {
            console.error('Ошибка при проверке авторизации:', error);
          }
        };

        checkAuth();
      }
    }
  }, [navigate, location]);

  return null; // Этот компонент не отображает ничего, только выполняет логику редиректа
};

export default AuthRedirect;