import React, { memo } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import res from "../../../resources/resources";

// Мемоизируем компонент для предотвращения лишних рендеров
const Button = memo(({ 
    text, 
    onClick, 
    width = null, // Ширина будет числом
    state, 
    disabled = false, 
    to, 
    className = "", 
    type = "primary", 
    size = "medium", 
    icon 
}) => {
    // Определение ширины на основе переданного пропса
    const widthStyle = width 
        ? (width <= 1 ? `${width * 100}%` : `${width}px`)  // Если число меньше или равно 1, это будет процент
        : null;

    const buttonClass = `button ${className} button--${type} button--${size} ${icon && !text ? 'radial' : ''}`;

    const buttonContent = (
        <div className="button__content">
            {icon && <ReactSVG src={res.icons[icon]}/>}
            {text && <span className="button__text">{text}</span>}
        </div>
    );

    const renderButton = (
        <button
            className={buttonClass}
            onClick={onClick}
            disabled={disabled}
            style={{ width: widthStyle }}  // Применяем инлайновый стиль
        >
            {buttonContent}
        </button>
    );

    // Если есть 'to', рендерим как ссылку
    return to ? <Link to={to} state={state} className={`${buttonClass} button__link`} style={{ width: widthStyle }}>{renderButton}</Link> : renderButton;
});

export default Button;