import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_spaces";
import request from "../../utils/requests/requests";
import * as UI from "../../components/ui";
import UserHeader from "../../components/block/header/header";
 const CreateNewSpace = () => {
    const navigate = useNavigate();
    const [spaceName, setSpaceName] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!spaceName) {
            setError("Название пространства обязательно");
            return;
        }

        setError("");
        setLoading(true);

        try {
            const response = await request(
                "POST",
                USER_ENDPOINTS.POST_CREATE_SPACE,
                { name: spaceName },
                false,
                true
            );

            if (response.status === "success") {
                navigate(`/dashboard/${response.space_hash}`);
            } else {
                setError(response.message || "Ошибка создания пространства");
            }
        } catch (err) {
            setError("Произошла ошибка, попробуйте позже");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <UserHeader />
            {error &&  <UI.ToastNotification status="error" message={error} />}
            <div className="navigate">
                <div className="go-back">
                    <UI.Button
                        icon="ic_24_arrow_left"
                        type="secondary"
                        onClick={() => navigate(-1)}
                        className="go-back-btn"
                    />
                    <h3>Создать новое пространство</h3>
                </div>
            </div>

            <div
                className="wrapper"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <form
                    className="form"
                    onSubmit={handleSubmit}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <UI.Input
                        id="spaceName"
                        label="Название пространства"
                        value={spaceName}
                        onChange={(e) => setSpaceName(e.target.value)}
                        errorMessage={error && "Название пространства обязательно"}
                    />
                    <div className="button-container">
                        <UI.Button
                            width="100%"
                            text={loading ? "Создание..." : "Создать"}
                            onClick={handleSubmit}
                        />
                        <UI.Button
                            width="100%"
                            type="secondary"
                            text="Назад"
                            onClick={() => navigate("/dashboard")}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateNewSpace;