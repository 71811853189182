import React from "react";
import res from "../../../../resources/resources";
const Empty = () => (
  <div className="no-results">
    <img src={res.illustrations['ill_fox_not_found']} alt="Fox Not Found" />
    
    <strong>Ничего не нашлось</strong>
    <p>Может попробовать написать по-другому?</p>
  </div>
);

export default Empty;