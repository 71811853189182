import React, { createContext, useState, useContext } from "react";

// Создание контекста
const GlobalContext = createContext();

// Провайдер глобального контекста
export const GlobalStates = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false); // Состояние авторизации
    const [bearer, setBearer] = useState(null); // Токен авторизации
    
    const [userInfo, setUserInfo] = useState(""); // Данные о пользователе
    
    
    const [spaceInfo, setSpaceInfo] = useState(""); // Данные о пространстве
    const [boxInfo, setBoxInfo] = useState(""); // Данные о коробке
    const [itemInfo, setItemInfo] = useState(""); // Данные о предмете
    const [subscriptionInfo, setSubscriptionInfo] = useState(""); // Данные о подписке

    // Значения, которые будут доступны через контекст
    const value = {
        userInfo,
        setUserInfo,
        spaceInfo,
        setSpaceInfo,
        boxInfo,
        setBoxInfo,
        itemInfo,
        setItemInfo,
        isAuth,
        setIsAuth,
        bearer,
        setBearer,
        subscriptionInfo,
        setSubscriptionInfo,
    };

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

// Кастомный хук для использования контекста
export const useGlobalContext = () => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error("useGlobalContext must be used within a GlobalStates");
    }
    return context;
};