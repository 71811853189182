import React from "react";
import { Link } from "react-router-dom";

const Success = ({ searchResults }) => (
  <>
    {searchResults.spaces.length > 0 && (
      <div className="results">
        <h4>Пространства:</h4>
        <ul>
          {searchResults.spaces.map((space) => (
            <Link
              to={`/dashboard/${space.space_hash}`}
              key={space.space_hash}
              className="search-item"
            >
              <div className="search-item-content">
                <strong>{space.space_name}</strong>
                <p className="search-item-description">
                  Создано: {space.created_at}
                </p>
              </div>
            </Link>
          ))}
        </ul>
      </div>
    )}

    {searchResults.boxes.length > 0 && (
      <div className="results">
        <h4>Коробки:</h4>
        <ul>
          {searchResults.boxes.map((box) => (
            <Link
              to={`/dashboard/${box.space_hash}/${box.box_hash}`}
              key={box.box_hash}
              className="search-item"
            >
              <div className="search-item-content">
                <strong>{box.box_name}</strong>
                <p className="search-item-description">{box.description}</p>
              </div>
            </Link>
          ))}
        </ul>
      </div>
    )}

    {searchResults.items.length > 0 && (
      <div className="results">
        <h4>Предметы:</h4>
        <ul>
          {searchResults.items.map((item) => (
            <Link
              to={`/dashboard/${item.space_hash}${item.box_hash ? `/${item.box_hash}` : ''}`}
              key={item.item_hash}
              className="search-item"
            >
              <div className="search-item-content">
                <strong>{item.item_name}</strong>
                <p className="search-item-description">{item.description}</p>
              </div>
              <img
                width="100px"
                src={`https://boxyfox.ru/uploads/${item.photo_url}`}
                alt={item.item_name}
              />
            </Link>
          ))}
        </ul>
      </div>
    )}
  </>
);

export default Success;