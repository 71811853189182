import React, { useState, useEffect } from "react";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_spaces";
import request from "../../utils/requests/requests";
import { useNavigate } from "react-router-dom";
import UserHeader from "../../components/block/header/header";
import * as UI from "../../components/ui";
import { useGlobalContext } from "../../utils/context/globalStates";
import handleDelete from "../../utils/misc/handleDelete"; // Импортируем функцию удаления
import SpaceCard from "../../components/elements/card/spaceCard"

const GetSpaces = () => {
    const navigate = useNavigate();
    const { spaceInfo, setSpaceInfo } = useGlobalContext(); // Получаем глобальные состояния
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [openMenuId, setOpenMenuId] = useState(null); // Управляем состоянием открытого меню

    // Функция для получения данных о пространствах
    const fetchSpaces = async () => {
        setError("");
        setLoading(true);

        try {
            const data = await request("GET", USER_ENDPOINTS.GET_SPACES, false, true); // true - с куками
            if (data && data.status === "success" && Array.isArray(data.spaces)) {
                setSpaceInfo(data.spaces);
            } else {
                setError(data.message || "Failed to load spaces");
            }
        } catch (err) {
            setError("An error occurred, please try again later");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSpaces(); // Вызываем функцию для получения данных при монтировании компонента
    }, []); // Пустой массив зависимостей для однократного вызова

    const handleSpaceDelete = async (e, spaceHash) => {
        // Вызов handleDelete для удаления пространства
        const result = await handleDelete({
            e,
            endpoint: USER_ENDPOINTS.POST_DELETE_SPACE,
            params: { space_hash: spaceHash },
            successRoute: `/dashboard/`,
            confirmMessage: "Вы уверены, что хотите удалить это пространство? Это действие нельзя будет отменить.",
            setError,
            setLoading,
            navigate,
        });

        // Если удаление прошло успешно, обновляем список пространств
        if (result) fetchSpaces();
    };

    return (
        <div className="container">
            <title>Мои пространства</title>
            <UserHeader />
            <div className="navigate">
                <div className="go-back">
                    <UI.Button icon="ic_24_garage" type="secondary" to={`/dashboard/`} />
                    <h3 className="header">Мои пространства</h3>
                </div>
                <div className="actions">
                    <UI.Button text="Создать пространство" to={"/dashboard/createSpace"} className="create-btn" />
                </div>
            </div>
            {/* {loading && <div className="loading">🤔</div>} */}
            {error && <div className="error">{error}</div>}
            {spaceInfo && spaceInfo.length > 0 ? (
                <ul className="spaces-list">
                    {spaceInfo.map((space, index) => (
                        <SpaceCard
                            key={index}
                            space={space} // Передаем пространство
                            openMenuId={openMenuId} // Передаем состояние открытого меню
                            setOpenMenuId={setOpenMenuId} // Передаем функцию для изменения состояния
                            setError={setError} // Передаем setError
                            setLoading={setLoading} // Передаем setLoading
                            updateState={setSpaceInfo} // Передаем функцию для обновления списка
                            handleDelete={handleSpaceDelete} // Передаем функцию удаления
                        />
                    ))}
                </ul>
            ) : (
                <div className="no-spaces">No spaces available</div>
            )}
        </div>
    );
};

export default GetSpaces;