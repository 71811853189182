import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_subscription";
import request from "../../utils/requests/requests";
import convertToClientTimeZone from "../../utils/time/convertToUserTz";

import UserHeader from "../../components/block/header/header";
import * as UI from "../../components/ui";
 
const PaymentHistory = () => {
    const navigate = useNavigate();
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchPaymentHistory = async () => {
            setLoading(true);
            setError("");

            try {
                const data = await request("GET", USER_ENDPOINTS.GET_HISTORY_PAYMENTS, false, true);
                if (data.status === "success") {
                    setPaymentHistory(data.payment_history || []);
                } else {
                    setError(data.message || "Failed to load subscription data");
                }
            } catch (err) {
                setError("An error occurred, please try again later");
                console.error("Error:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchPaymentHistory();
    }, []);

    return (
        <div className="container">
            <UserHeader />
            {error &&  <UI.ToastNotification status="error" message={error} />}
                        
            <div className="navigate">
                <div className="go-back">
                    <UI.Button type="secondary" icon="ic_24_arrow_left" onClick={() => navigate(-1)} className="back-btn" />
                    <h3>История платежей</h3>
                </div>
                {/* <div className="actions">
                    <UI.Button icon="ic_24_coupon" type="secondary" to="/dashboard/subscription/usePromocode" className="payment-history__action-btn" />
                    <UI.Button icon="ic_24_card" type="secondary" to="/dashboard/subscription/paymentMethods" className="payment-history__action-btn" />
                </div> */}
            </div>

            {loading && <div className="payment-history__loading">🤔</div>}

            {paymentHistory.length > 0 ? (
                <div className="payment-history__list">
                    {paymentHistory.map((payment, index) => (
                        <div key={index} className="payment-history__card">
                            <div className="payment-history__card-header">
                                <div className="payment-history__payment-hash">{payment.payment_hash}</div>
                                <div className={`payment-history__status payment-history__status--${payment.status.toLowerCase()}`}>
                                    {payment.status}
                                </div>
                            </div>
                            <div className="payment-history__card-body">
                                <div className="payment-history__date">
                                    Date: {convertToClientTimeZone(payment.srv_create_date)}
                                </div>
                                <div className="payment-history__type">
                                    Type: {payment.type}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="payment-history__no-data">No payment history found.</p>
            )}
        </div>
    );
};

export default PaymentHistory;