const icons = require.context('./icons', false, /\.svg$/);
const illustrations = require.context('./illustrations', false, /\.(svg|png|jpg|jpeg)$/);

// Функция для добавления класса к SVG
const addClassToSVG = (svgContent, className) => {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgContent, "image/svg+xml");
  const svgElement = svgDoc.querySelector("svg");

  if (svgElement) {
    svgElement.classList.add(className);
    return new XMLSerializer().serializeToString(svgElement); 
  }

  return svgContent;
};

// Функция для преобразования изображения в Base64
const convertToBase64 = (url) => {
  return fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

// Функция для проверки кеша
const getCachedResource = (key) => {
  const cached = localStorage.getItem(key);
  return cached ? JSON.parse(cached) : null;
};

// Функция для добавления ресурса в кеш
const cacheResource = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

// Формируем объект с ресурсами
const res = {
  icons: icons.keys().reduce((acc, path) => {
    const name = path.replace('./', '').split('.')[0];
    
    // Проверяем кеш на наличие ресурса
    const cachedIcon = getCachedResource(`icon_${name}`);
    if (cachedIcon) {
      acc[name] = cachedIcon;
      return acc;
    }

    try {
      const iconContent = icons(path); 
      const iconWithClass = addClassToSVG(iconContent, ""); 
      acc[name] = iconWithClass;
      
      // Кешируем полученное содержимое
      cacheResource(`icon_${name}`, iconWithClass); 
    } catch (e) {
      console.error(`Ошибка загрузки иконки: ${path}`, e); 
    }

    return acc;
  }, {}),
  
  illustrations: illustrations.keys().reduce((acc, path) => {
    const name = path.replace('./', '').split('.')[0];
    
    // Проверяем кеш на наличие ресурса
    const cachedIllustration = getCachedResource(`illustration_${name}`);
    if (cachedIllustration) {
      acc[name] = cachedIllustration;
      return acc;
    }

    try {
      const illustrationPath = illustrations(path); // Получаем путь к изображению

      // Преобразуем изображение в Base64 для кеширования
      convertToBase64(illustrationPath)
        .then((base64Image) => {
          acc[name] = base64Image;
          // Кешируем Base64 изображение
          cacheResource(`illustration_${name}`, base64Image);
        })
        .catch((e) => {
          console.error(`Ошибка преобразования изображения в Base64: ${path}`, e);
        });
      
    } catch (e) {
      console.error(`Ошибка загрузки иллюстрации: ${path}`, e); 
    }

    return acc;
  }, {}),
};

export default res;