// src/components/header/header.js
import React, { useState, useEffect } from "react";

import { ENDPOINTS as USER_ENDPOINTS } from "../../../utils/requests/e_user";
import { useGlobalContext } from "../../../utils/context/globalStates";
import request from "../../../utils/requests/requests";

import Success from "./layout/success";
import Empty from "./layout/empty";
import Loading from "./layout/loading";
import Error from "./layout/error";

    /**
     * Component that displays user information in the header.
     * 
     * The component handles three states:
     *   1. loading: The user information is being retrieved from the server.
     *   2. error: The retrieval of user information failed.
     *   3. success: The user information is available and will be displayed.
     * 
     * If the user information is not available, the component displays an empty state.
     * 
     * The component also stores the user information in the session storage
     * with a hash of the data. When the component is mounted, it checks if the
     * hash has changed and updates the user information in the session storage
     * only if the hash has changed.
     * 
     * The component uses the global state context to get the user information
     * and set it when it changes.
     * 
     * @returns A JSX element that displays the user information in the header.
     */
const UserHeader = () => {
    const { userInfo, setUserInfo } = useGlobalContext(); // Получаем глобальные состояния
    const [loadingStatus, setLoadingStatus] = useState("loading");
    const [error, setError] = useState("");

    // Функция для генерации хэша из данных
    const hashData = async (data) => {
        const encoder = new TextEncoder();
        const dataBuffer = encoder.encode(JSON.stringify(data)); // Преобразуем данные в строку и кодируем в байты
        const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer); // Генерируем хэш
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // Преобразуем в массив
        const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join(''); // Преобразуем в строку HEX
        return hashHex;
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            setError("");
            setLoadingStatus("loading");

            const cachedUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));// Извлекаем данные из sessionStorage
            const cachedHash = sessionStorage.getItem("userInfoHash");// Извлекаем хэш
            if (cachedUserInfo) {
                setUserInfo(cachedUserInfo);
                setLoadingStatus("cached");
            }

            try {
                const data = await request("GET", USER_ENDPOINTS.GET_USER_INFO, false, true);

                if (data && data.auth && data.user) {// Проверяем, что data существует и имеет нужную структуру
                    const newHash = await hashData(data);

                    if (cachedHash !== newHash) {// Проверяем, что хэш изменился
                        setUserInfo(data);
                        sessionStorage.setItem("userInfo", JSON.stringify(data));
                        sessionStorage.setItem("userInfoHash", newHash);
                        setLoadingStatus("updated");
                    } else {
                        setLoadingStatus("cached");
                    }
                } else {
                    setError(data.message || "Failed to load user info");
                    setLoadingStatus("error");
                }
            } catch (err) {
                setError("An error occurred, please try again later");
                setLoadingStatus("error");
            }
        };

        fetchUserInfo();
    }, [setUserInfo]); // Добавляем setUserInfo в зависимости, чтобы не было ошибок

    useEffect(() => {
        console.info("userInfo changed"); // Логируем изменения в userInfo
    }, [userInfo]);

    // В зависимости от статуса загрузки отображаем нужную информацию
    if (loadingStatus === "loading") {return <Loading/>;}
    if (loadingStatus === "error") {return <Error/>;}
    if (!userInfo) {return <Empty/>;}
    return (<Success userInfo={userInfo} />);
};

export default UserHeader;
