// src/pages/UserAuth/LoginPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Импортируем useNavigate
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_user";
import request from "../../utils/requests/requests";
import * as UI from "../../components/ui";
import { SmartCaptcha } from '@yandex/smart-captcha';
import res from "../../resources/resources";
/**
 * LoginPage component provides a user interface for logging into the application.
 * It includes input fields for email and password, along with basic validation 
 * for both fields. The component handles user authentication requests and displays 
 * error messages when needed. On successful authentication, it stores the session 
 * token and redirects the user to the dashboard. It also provides navigation 
 * buttons to registration and recovery pages.
 */
const LoginPage = () => {
    const navigate = useNavigate(); // Получаем функцию для навигации
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(''); // Для хранения token с капчи
    const [captchaKey, setCaptchaKey] = useState(Date.now());  // Ключ для перемонтирования капчи

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Простая валидация
        if (!email || !password) {
            setError("Все поля обязательны");
            return;
        }

        // Регулярное выражение для email
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(email)) {
            setError("Некорректный email");
            return;
        }

        if (!token) {
            setError("Пожалуйста, пройдите капчу");
            return;
        }

        setError("");
        setLoading(true);

        try {
            const data = await request("POST", USER_ENDPOINTS.POST_AUTH_USER, { email, password, captcha_token: token });

            if (data.status === "success") {
                // Сохраняем токен в localStorage
                localStorage.setItem("session_token", data.data.session_token);
                navigate("/dashboard");
            } else {
                setError(data.message || "Авторизация не удалась");
                setCaptchaKey(Date.now()); // Перемонтируем капчу в случае ошибки
            }
        } catch (err) {
            setError("Что-то пошло не так, попробуйте еще раз");
            setCaptchaKey(Date.now()); // Перемонтируем капчу в случае ошибки
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="entry-page">
            <title>Вход</title>
            {error && <UI.ToastNotification status="error" message={error} />}
            
            <img className="image" src={res.illustrations['ill_fox_in_a_box']} alt="welcome to boxyfox" />
            <form className="entry-page__container" onSubmit={handleSubmit}>
                <h1 className="title">Рад видеть вас!</h1>
                <p className="subtitle">Давайте приведем все в порядок</p>
                <UI.Input  label="Email" type="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <UI.Input minLength={6} label="Пароль" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                
                {/* CAPTCHA */}
                <SmartCaptcha 
                    sitekey="ysc1_KvpV7l8Auc3wNPbW859VDH3y2qnvrQkETA5RnOSI43c3bb09" 
                    onSuccess={setToken}
                    key={captchaKey}  // Ключ для перерендеринга капчи
                />
                <div className="button-container">
                    <div className="base-buttons">
                        <UI.Button width="252" type="secondary" text="Создать аккаунт" to={"/registration"} />
                        <UI.Button width="252" text={loading ? "Загрузка..." : "Войти"} onClick={handleSubmit} />
                    </div>
                    <div className="addictional-buttons">
                        <UI.Button width="252" type="secondary" text="Забыли пароль?" to={"/recover"} />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default LoginPage;