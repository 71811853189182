import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const CredentialPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [formStatus, setFormStatus] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Вставьте здесь логику для отправки сообщения на сервер
    setFormStatus('Ваше сообщение отправлено!');
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>Контакты</h1>
        <p style={styles.subtitle}>Свяжитесь с нами, если у вас есть вопросы!</p>
      </header>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Мы всегда рады помочь</h2>
        <p>Если у вас есть вопросы, предложения или проблемы, не стесняйтесь обращаться к нам. Мы готовы помочь!</p>

        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputGroup}>
            <label htmlFor="name" style={styles.label}>Ваше имя:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              style={styles.input}
              required
            />
          </div>

          <div style={styles.inputGroup}>
            <label htmlFor="email" style={styles.label}>Ваш email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              style={styles.input}
              required
            />
          </div>

          <div style={styles.inputGroup}>
            <label htmlFor="message" style={styles.label}>Сообщение:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              style={styles.textarea}
              required
            />
          </div>

          <button type="submit" style={styles.submitButton}>Отправить</button>
        </form>

        {formStatus && <p style={styles.formStatus}>{formStatus}</p>}
      </section>

      <footer style={styles.footer}>
        <Link to="/" style={styles.button}>На главную</Link>
      </footer>
    </div>
  );
};

// Стилизация страницы
const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    color: '#333',
    margin: 0,
    padding: 0,
    textAlign: 'center',
  },
  header: {
    backgroundColor: '#ff6347',
    padding: '50px 0',
    color: 'white',
  },
  title: {
    fontSize: '3rem',
    margin: '0',
  },
  subtitle: {
    fontSize: '1.5rem',
    marginTop: '10px',
  },
  section: {
    padding: '50px 20px',
    backgroundColor: '#fff',
  },
  sectionTitle: {
    fontSize: '2rem',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '600px',
    margin: '0 auto',
  },
  inputGroup: {
    marginBottom: '15px',
    width: '100%',
  },
  label: {
    fontSize: '1.2rem',
    marginBottom: '5px',
    display: 'block',
    textAlign: 'left',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid #ddd',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid #ddd',
    minHeight: '150px',
  },
  submitButton: {
    backgroundColor: '#ff6347',
    color: 'white',
    padding: '15px 30px',
    fontSize: '1.2rem',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
  },
  formStatus: {
    marginTop: '20px',
    fontSize: '1.2rem',
    color: '#4caf50',
  },
  footer: {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    fontSize: '0.9rem',
  },
  button: {
    padding: '15px 30px',
    backgroundColor: '#ffffff',
    color: '#ff6347',
    fontSize: '1.2rem',
    borderRadius: '5px',
    textDecoration: 'none',
  },
};

export default CredentialPage;