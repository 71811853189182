// src/utils/requests/e_user.js

// Эндпоинты для запросов пользователя
export const ENDPOINTS = {
    GET_USER_VERIFICATION: (hash, userId) => `/user/check_verification.php?hash=${hash}&user_id=${userId}`,
    GET_USER_AUTH: "/user/check_auth.php",
    POST_CREATE_USER: "/user/create_user.php",
    POST_EDIT_USER: "/user/edit_user.php",
    POST_AUTH_USER: "/user/auth_user.php",
    POST_RECOVER_USER: "/user/recover_user.php",
    POST_LOGOUT_USER: "/user/logout.php",
    POST_CHECK_AUTH_REDIRECT: "/user/check_auth_redirect.php",
    GET_USER_INFO: "/user/get_user_info.php",
};