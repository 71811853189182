import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_subscription";
import request from "../../utils/requests/requests";
import UserHeader from "../../components/block/header/header";
import * as UI from "../../components/ui";
import res from "../../resources/resources";

const PromocodePage = () => {
    const navigate = useNavigate();
    const [promocode, setPromocode] = useState("");
    const [activatedPromocodes, setActivatedPromocodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [applyLoading, setApplyLoading] = useState(false);
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    // Получение активированных промокодов
    const fetchActivatedPromocodes = async () => {
        setLoading(true);
        try {
            const response = await request(
                "GET",
                USER_ENDPOINTS.GET_PROMOCODE,
                null,
                false,
                true
            );
            if (response.status === "success") {
                setActivatedPromocodes(response.activated_promocodes || []);
            } else {
                setError(response.message || "Failed to fetch promocodes.");
            }
        } catch (err) {
            setError("An error occurred while fetching promocodes.");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchActivatedPromocodes();
    }, []);

    // Применение промокода
    const applyPromocode = async () => {
        setError("");
        setSuccessMessage("");
        setApplyLoading(true);

        if (!promocode) {
            setError("Please enter a promocode.");
            setApplyLoading(false);
            return;
        }

        try {
            const response = await request(
                "POST",
                USER_ENDPOINTS.POST_PROMOCODE,
                { promocode },
                false,
                true
            );
            if (response.status === "success") {
                setSuccessMessage("Promocode applied successfully!");
                setPromocode("");
                await fetchActivatedPromocodes(); // Обновляем список промокодов
            } else {
                setError(response.message || "Failed to apply promocode.");
            }
        } catch (err) {
            setError("An error occurred while applying the promocode.");
            console.error("Error:", err);
        } finally {
            setApplyLoading(false);
        }
    };

    // Удаление промокода
    const deletePromocode = async (code) => {
        setLoading(true);
        setError("");
        setSuccessMessage("");

        try {
            const response = await request(
                "POST",
                USER_ENDPOINTS.POST_DELETE_PROMOCODE,
                { promocode: code },
                false,
                true
            );

            if (response.status === "success") {
                setSuccessMessage(`Promocode ${code} deleted successfully.`);
                await fetchActivatedPromocodes(); // Обновляем список промокодов
            } else {
                setError(response.message || "Failed to delete promocode.");
            }
        } catch (err) {
            setError("An error occurred while deleting the promocode.");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <UserHeader />
            {error &&  <UI.ToastNotification status="error" message={error} />}
            <div className="navigate">
                <div className="go-back">
                    <UI.Button type="secondary" icon="ic_24_arrow_left" onClick={() => navigate(-1)} className="back-btn" />
                    <h3>Промокод</h3>
                </div>
                {/* <div className="actions">
                    <UI.Button icon="ic_24_bill" type="secondary" to="/dashboard/subscription/history" className="payment-btn" />
                    <UI.Button icon="ic_24_card" type="secondary" to="/dashboard/subscription/paymentMethods" className="payment-btn" />
                </div> */}
            </div>

           
            <div className="container">
                <UI.Input
                    type="text"
                    label={"Промокод"}
                    value={promocode}
                    onChange={(e) => setPromocode(e.target.value)}
                    placeholder="Промокод"
                    disabled={applyLoading}
                />
               
                <UI.Button
                    text={applyLoading ? "Применяем..." : "Применить"}
                    onClick={applyPromocode}
                    disabled={applyLoading}
                />
            </div>
            {loading && <div className="loading">Loading...</div>}
            <div className="promocode-section">
                {activatedPromocodes.length > 0 ? (
                    <ul>
                    <h2>Активированный промокод</h2>
                        {activatedPromocodes.map((promo, index) => (
                            <li key={index} className="promocode-item">
                                <p>
                                    <strong>Код:</strong> {promo.code}
                                </p>
                                <p>
                                    <strong>Описание:</strong> {promo.description || "Нет описания"}
                                </p>
                                <p>
                                    <strong>Активирован:</strong> {promo.activated_at || "N/A"}
                                </p>
                                <UI.Button
                                    text="Удалить промокод"
                                    onClick={() => deletePromocode(promo.code)}
                                    disabled={loading}
                                />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="empty">
                        <img width="200px" height="200px" src={res.illustrations['ill_fox_not_found']} alt="Fox Not Found" />
                        <p>Нет активных промокодов</p>
                    </div>
                )}
            </div>

           

          
        </div>
    );
};

export default PromocodePage;