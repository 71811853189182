import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPage = () => {
  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>Политика конфиденциальности</h1>
        <p style={styles.subtitle}>Мы ценим вашу конфиденциальность и защищаем ваши данные.</p>
      </header>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Что мы собираем?</h2>
        <p>Мы можем собирать следующие данные:</p>
        <ul style={styles.list}>
          <li>Имя и контактная информация (например, email, телефон).</li>
          <li>Данные о вашем устройстве и браузере (например, IP-адрес, user-agent, locale).</li>
          <li>История посещений нашего сайта.</li>
        </ul>

        <h2 style={styles.sectionTitle}>Как мы используем вашу информацию?</h2>
        <p>Мы используем вашу информацию для следующих целей:</p>
        <ul style={styles.list}>
          <li>Предоставление наших услуг и улучшение пользовательского опыта.</li>
          <li>Отправка уведомлений и обновлений, если вы подписались на рассылку.</li>
          <li>Обработка ваших запросов и сообщений.</li>
        </ul>

        <h2 style={styles.sectionTitle}>Как мы защищаем вашу информацию?</h2>
        <p>Мы применяем различные меры для защиты ваших данных:</p>
        <ul style={styles.list}>
          <li>Шифрование данных для предотвращения несанкционированного доступа.</li>
          <li>Регулярное обновление системы безопасности.</li>
          <li>Ограниченный доступ к вашим данным для персонала компании.</li>
        </ul>

        <h2 style={styles.sectionTitle}>Ваши права</h2>
        <p>Вы имеете следующие права в отношении своих данных:</p>
        <ul style={styles.list}>
          <li>Право на доступ к вашим личным данным.</li>
          <li>Право на исправление или удаление ваших данных.</li>
          <li>Право на ограничение обработки ваших данных.</li>
        </ul>

        <h2 style={styles.sectionTitle}>Как связаться с нами?</h2>
        <p>Если у вас есть вопросы по поводу политики конфиденциальности, вы можете связаться с нами:</p>
        <ul style={styles.list}>
          <li>По email: <a href="mailto:support@company.com" style={styles.link}>support@company.com</a></li>
        </ul>
      </section>

      <footer style={styles.footer}>
        <Link to="/" style={styles.button}>На главную</Link>
      </footer>
    </div>
  );
};

// Стилизация страницы
const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    color: '#333',
    margin: 0,
    padding: 0,
    textAlign: 'center',
  },
  header: {
    backgroundColor: '#ff6347',
    padding: '50px 0',
    color: 'white',
  },
  title: {
    fontSize: '3rem',
    margin: '0',
  },
  subtitle: {
    fontSize: '1.5rem',
    marginTop: '10px',
  },
  section: {
    padding: '50px 20px',
    backgroundColor: '#fff',
  },
  sectionTitle: {
    fontSize: '2rem',
    marginBottom: '20px',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    textAlign: 'left',
    marginLeft: '20px',
  },
  link: {
    color: '#ff6347',
    textDecoration: 'none',
  },
  footer: {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    fontSize: '0.9rem',
  },
  button: {
    padding: '15px 30px',
    backgroundColor: '#ffffff',
    color: '#ff6347',
    fontSize: '1.2rem',
    borderRadius: '5px',
    textDecoration: 'none',
  },
};
 
export default PrivacyPage;