import React from 'react';
import { ReactSVG } from 'react-svg';
import * as UI from '../../components/ui'; // Импортируем компоненты UI
import convertToClientTimeZone from '../../utils/time/convertToUserTz'; // Импортируем утилиты для склонений и конвертации времени
import res from '../../resources/resources'; // Путь к ресурсам (иконки, изображения)

const SubscriptionCard = ({ subscriptionData, className = "", handlePaymentHistory, handleUpgradePlan }) => {
    const getDeclension = (number, singular, few, many) => {
        const mod10 = number % 10;
        const mod100 = number % 100;
    
        if (mod100 >= 11 && mod100 <= 14) {
            return many; // 11, 12, 13, 14
        }
        if (mod10 === 1) {
            return singular; // 1 коробка
        }
        if (mod10 >= 2 && mod10 <= 4) {
            return few; // 2, 3, 4 коробки
        }
        return many; // 5+ коробок
    };
    return (
        <div className={`subscription-info ${className}`}>
            <img 
                src={res.illustrations[`ill_${subscriptionData.alias}`]} 
                alt={`My subscription plan: ${subscriptionData.alias}`} 
            />
            <div className="subscription-details">    
            <h3 className="header">{subscriptionData.name}</h3>
                {subscriptionData.active_till && (
                <div>Активна до: {convertToClientTimeZone(subscriptionData.active_till)}</div>
                )}
                <h2 className="price">{subscriptionData.price}₽ в месяц</h2>
                <div className="description">{subscriptionData.description}</div>
                
                <div className="section">
                    <h3 className="header">Лимиты</h3>
                    <div className="description">
                        <ReactSVG src={res.icons['ic_24_garage']} className="icon-path" />
                        {`${subscriptionData.limit_spaces} ${getDeclension(subscriptionData.limit_spaces, "пространство", "пространства", "пространств")}`}
                    </div>
                    <div className="description">
                        <ReactSVG src={res.icons['ic_24_box']} className="icon-path" />
                        {`${subscriptionData.limit_boxes} ${getDeclension(subscriptionData.limit_boxes, "коробка", "коробки", "коробок")}`}
                    </div>
                    <div className="description">
                        <ReactSVG src={res.icons['ic_24_label']} className="icon-path" />
                        {`${subscriptionData.limit_items} ${getDeclension(subscriptionData.limit_items, "вещь", "вещи", "вещей")}`}
                    </div>
                </div>
                
                <div className="section">
                    <h3 className="header">Функции</h3>
                    {subscriptionData.qr_codes ? (
                        <div className="description">
                            <ReactSVG src={res.icons['ic_24_ok_circle']} className="icon-path" />
                            Генератор QR
                        </div>
                    ) : null}
                    {subscriptionData.qr_codes_customize ? (
                        <div className="description">
                            <ReactSVG src={res.icons['ic_24_ok_circle']} className="icon-path" />
                            Кастомизация QR
                        </div>
                    ) : null}
                    {subscriptionData.ai_features ? (
                        <div className="description">
                            <ReactSVG src={res.icons['ic_24_ok_circle']} className="icon-path" />
                            AI функции (поиск и автоописание)
                        </div>
                    ) : null
                    }
                    {subscriptionData.integrations ? (
                            <div className="description">
                                <ReactSVG src={res.icons['ic_24_ok_circle']} className="icon-path" />
                                Интеграция с умными ассистентами
                            </div>
                        ) : null
                    }
                </div>
                
                <div className="tier-actions">
                {className === "current" && (
                        <UI.Button 
                            width="100%" 
                            type="secondary"
                            text="Текущий тариф" 
                            className="payment-btn" 
                            disabled
                        />
                )}

                {className === "available" && (
                    <UI.Button
                        text={`Улучшить до ${subscriptionData.name}`}
                        onClick={() => handleUpgradePlan(subscriptionData.alias)} // Upgrade function
                        className="upgrade-btn"
                    />
                )}

                {className === "unavailable" && (
                    <UI.Button
                        text="Недоступно"
                        type="secondary"
                        className="upgrade-btn"
                        disabled // Если кнопка "Недоступно", возможно, имеет смысл отключить ее
                    />
                )}
                </div>
            </div>
        </div>
    );
};

export default SubscriptionCard;