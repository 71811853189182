import AppRouter from "./utils/router/router";
import { YMInitializer } from 'react-yandex-metrika';
import { GlobalStates } from "./utils/context/globalStates";
import "./styles/main.scss";
import { Helmet } from "react-helmet";

const App = () => {
    return (
        <div>
            <Helmet>
                <meta name="theme-color" content="#ff6600" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            </Helmet>
            <GlobalStates>
                <AppRouter />
            </GlobalStates>
            {/* <YMInitializer accounts={[99515769]} options={{ webvisor: true }} version="2" /> */}
        </div>
    ); 
};

export default App;