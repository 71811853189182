import React, { useState } from "react";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_user";
import request from "../../utils/requests/requests";

import * as UI from "../../components/ui";
import { SmartCaptcha } from '@yandex/smart-captcha';
import res from "../../resources/resources";
/**
 * RegistrationPage component provides a user interface for registration.
 * It includes input fields for email, username, password and confirmation
 * of the password. The component handles user registration requests and
 * displays error messages when needed. On successful registration, it
 * navigates the user to the login page.
 *
 * @returns {React.ReactElement} The JSX element to be rendered.
 */
const RegistrationPage = () => {
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const [captchaKey, setCaptchaKey] = useState(Date.now());  // Ключ для перемонтирования капчи
    const [successMessage, setSuccessMessage] = useState("");  // Сообщение об успешной регистрации

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Простая валидация
        if (!email || !username || !password || !confirmPassword) {
            setError("Все поля обязательны");
            return;
        }

        // Проверка на латиницу в юзернейме
        const usernameRegex = /^[a-zA-Z0-9_]+$/;  // Разрешаем латиницу, цифры и подчеркивания
        if (!usernameRegex.test(username)) {
            setError("Логин должен содержать только латинские буквы, цифры и подчеркивания");
            return;
        }

        // Проверка совпадения пароля и подтверждения
        if (password !== confirmPassword) {
            setError("Пароли не совпадают");
            return;
        }

        // Регулярное выражение для email
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(email)) {
            setError("Некорректный email");
            return;
        }

        if (!token) {
            setError("Пожалуйста, пройдите капчу");
            return;
        }

        setError("");
        setLoading(true);

        try {
            // Добавляем token капчи в запрос
            const data = await request("POST", USER_ENDPOINTS.POST_CREATE_USER, { email, name, username, password, captcha_token: token }, false, true);

            if (data.status === 'success') {
                console.log("User registered:", data);
                // Устанавливаем сообщение об успешной регистрации
                setSuccessMessage(data.message || "Регистрация прошла успешно, проверьте почту");
                // Можно добавить редирект или перенаправление на страницу входа
            } else {
                setError(data.message || "Регистрация не удалась");
                setCaptchaKey(Date.now()); // Изменяем ключ для перерендера капчи
            }
        } catch (err) {
            setError("Что-то пошло не так, попробуйте ещё раз");
            setCaptchaKey(Date.now()); // Изменяем ключ для перерендера капчи
            console.error("Error:", err); // Дополнительное логирование ошибки для отладки
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="entry-page">
            <title>Регистрация</title>
            {error && <UI.ToastNotification status="error" message={error} />}
            {successMessage && <UI.ToastNotification status="success" message={successMessage} />}

            <img className="image" src={res.illustrations['ill_fox_in_a_box']} alt="welcome to boxyfox" />

            <form className="entry-page__container" onSubmit={handleSubmit}>
                <h1 className="title">Привет, я-Бокси!</h1>
                <p className="subtitle">Я помогу тебе создать настоящий порядок</p>
                
                <UI.Input 
                    label="Email" 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                />
                
                <UI.Input 
                    label="Юзернейм" 
                    type="text" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                />
                
                <UI.Input 
                    label="Имя" 
                    type="text" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                />
                
                <UI.Input 
                    minLength="6" 
                    label="Пароль" 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                />
                
                <UI.Input 
                    minLength="6" 
                    label="Подтверждение пароля" 
                    type="password" 
                    value={confirmPassword} 
                    onChange={(e) => setConfirmPassword(e.target.value)} 
                />
                
                {/* CAPTCHA */}
                <SmartCaptcha 
                    sitekey="ysc1_KvpV7l8Auc3wNPbW859VDH3y2qnvrQkETA5RnOSI43c3bb09" 
                    onSuccess={setToken}
                    key={captchaKey}  // Ключ для перерендеринга капчи
                />

                <div className="button-container">
                    <div className="base-buttons">
                        <UI.Button 
                            width="252"
                            type="secondary" 
                            text="Войти" 
                            to="/login" 
                        />
                        <UI.Button 
                            width="252" 
                            text="Создать аккаунт" 
                            onClick={handleSubmit} 
                        />
                    </div>
                    <div className="addictional-buttons">
                        <UI.Button 
                            width="252"
                            type="secondary" 
                            text="Забыли пароль?" 
                            to="/recover" 
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default RegistrationPage;