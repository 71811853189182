const convertToClientTimeZone = (dateString) => {
    const sourceTimeZone = "Europe/Moscow"; // Часовой пояс сервера
    // Создаём объект Date из строки даты и исходного часового пояса
    const sourceDate = new Date(
        new Date(dateString).toLocaleString("en-US", { timeZone: sourceTimeZone })
    );

    // Получаем часовой пояс клиента
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Конвертируем время в часовой пояс клиента
    const clientDate = new Date(
        sourceDate.toLocaleString("en-US", { timeZone: clientTimeZone })
    );

    // Возвращаем время в 24-часовом формате
    return clientDate.toLocaleString("en-US", {
        timeZone: clientTimeZone,
        hour12: false // Убираем AM/PM
    });
};
export default convertToClientTimeZone