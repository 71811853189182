import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_spaces";
import request from "../../utils/requests/requests";
import * as UI from "../../components/ui";
import UserHeader from "../../components/block/header/header";
 /**
 * CreateNewBox - a component for creating a new box
 * 
 * Renders a form with a box name input field, a description text area, and two buttons: "Create Box" and "Go Back".
 * 
 * When the user submits the form, the component sends a POST request to the server with the box name and description.
 * If the request is successful, the component redirects the user to the box page with the received box hash.
 * If the request fails, the component displays an error message.
 * 
 * @returns {React.ReactElement} The CreateNewBox component
 */
const CreateNewBox = () => {
    const navigate = useNavigate();
    const { spaceHash } = useParams();
    const [boxData, setBoxData] = useState({
        boxName: "",
        description: "",
    });
    const [error, setError] = useState(""); // Для ошибок
    const [loading, setLoading] = useState(false); // Для загрузки

    // Обработка изменений в форме
    const handleChange = (e) => {
        const { name, value } = e.target;
        setBoxData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Обработка отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();

        const { boxName, description } = boxData;

        if (!boxName) {
            setError("Введите название коробки");
            return;
        }

        setError("");
        setLoading(true);

        try {
            const response = await request(
                "POST",
                USER_ENDPOINTS.POST_CREATE_BOX,
                { space_hash: spaceHash, name: boxName, description },
                false,
                true
            );

            if (response.status === "success") {
                const boxHash = response.box_hash;
                navigate(`/dashboard/${spaceHash}/${boxHash}`);
            } else {
                setError(response.message || "Failed to create box");
            }
        } catch (err) {
            setError("Что-то пошло не так, попробуйте позже");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <UserHeader />
            {error &&  <UI.ToastNotification status="error" message={error} />}
            <div className="navigate">
                <div className="go-back">
                    <UI.Button
                        icon="ic_24_arrow_left"
                        type="secondary"
                        onClick={() => navigate(-1)}
                        className="go-back-btn"
                    />
                    <h3>Добавить коробку</h3>
                </div>
            </div>

            <div className="wrapper" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <form className="form" onSubmit={handleSubmit}>
                    <UI.Input
                        id="boxName"
                        name="boxName"
                        placeholder="Название"
                        label="Название"
                        value={boxData.boxName}
                        onChange={handleChange}
                        errorMessage={error && "Введите название коробки"}
                    />
                    <UI.TextArea
                        id="description"
                        name="description"
                        label="Описание"
                        placeholder="Описание коробки"
                        value={boxData.description}
                        onChange={handleChange}
                        errorMessage={error && "Введите описание коробки"}
                    />
                    <div className="button-container">
                        <UI.Button width="100%" text={loading ? "Создание..." : "Создать"} onClick={handleSubmit} />
                        <UI.Button
                            width="100%"
                            type="secondary"
                            text="Назад"
                            onClick={() => navigate(`/dashboard/${spaceHash}/`)}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateNewBox;