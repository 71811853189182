import React, { useState } from "react";

const TextArea = ({
  id,
  label,
  value,
  onChange,
  errorMessage,
  required,
  minLength,
  style,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="textarea-container">
      <textarea
        id={id}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={`textarea-field ${isFocused || value ? "focused" : ""} ${errorMessage ? "error-textarea" : ""}`}
        required={required}
        minLength={minLength}
        {...rest}
      />
      <label htmlFor={id} className="label">
        {label}
      </label>
      {errorMessage && (
        <div className="error-message">{errorMessage}</div>
      )}
    </div>
  );
};

export default TextArea;