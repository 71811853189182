// src/utils/requests/e_spaces.js

// Эндпоинты для запросов пространства
export const ENDPOINTS = {
    GET_SPACES: "/spaces/get_spaces.php",
    GET_ALL: "/spaces/get_all.php",
    GET_BOXES: (spaceId) => `/spaces/get_boxes.php?space_hash=${spaceId}`,
    // Переделанная версия GET_ITEMS с динамическим параметром
    GET_ITEMS: (spaceHash, boxHash = null, itemHash = null) => {
        let url = `/spaces/get_items.php?space_hash=${spaceHash}`;
        if (boxHash) {
            url += `&box_hash=${boxHash}`;
        }
        if (itemHash) {
            url += `&item_hash=${itemHash}`;
        }
        return url;
    },
    POST_CREATE_SPACE: "/spaces/create_space.php",
    POST_CREATE_BOX: "/spaces/create_box.php",
    POST_CREATE_ITEM: "/spaces/create_item.php",

    POST_EDIT_SPACE: "/spaces/edit_space.php",
    POST_EDIT_BOX: "/spaces/edit_box.php",
    POST_EDIT_ITEM: "/spaces/edit_item.php",

    POST_DELETE_SPACE: "/spaces/delete_space.php",
    POST_DELETE_BOX: "/spaces/delete_box.php",
    POST_DELETE_ITEM: "/spaces/delete_item.php",

    
    GET_BOXES_VIEWER: (spaceId) => `/spaces/get_boxes_viewer.php?space_hash=${spaceId}`,
    GET_ITEMS_VIEWER: (spaceHash, boxHash = null, itemHash = null) => {
        let url = `/spaces/get_items_viewer.php?space_hash=${spaceHash}`;
        if (boxHash) {
            url += `&box_hash=${boxHash}`;
        }
        if (itemHash) {
            url += `&item_hash=${itemHash}`;
        }
        return url;
    },
};