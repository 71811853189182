import React from "react";

const FileInput = ({ id, label, onChange, style, accept }) => {
    return (
        <div style={{ marginBottom: "15px" }}>
            <label htmlFor={id} style={{ display: "block", marginBottom: "5px" }}>
                {label}
            </label>
            <input
                accept={accept} // передаем accept сюда
                type="file"
                id={id}
                onChange={onChange}
                style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    ...(style || {}),
                }}
            />
        </div>
    );
};

export default FileInput;