// src/components/atoms/card/boxCard.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as UI from "../../ui";
import { ReactSVG } from "react-svg"; // Импортируем компонент ReactSVG
import handleDelete from "../../../utils/misc/handleDelete";
import { ENDPOINTS as USER_ENDPOINTS } from "../../../utils/requests/e_spaces";
import res from "../../../resources/resources";

const BoxCard = ({ box, openMenuId, setOpenMenuId, setError, setLoading, updateState }) => {
    const navigate = useNavigate();
    const [isDeleted, setIsDeleted] = useState(false); // Состояние для отслеживания удаления
    
    // Функция переключения состояния меню
    const toggleMenu = (e, id) => {
        e.stopPropagation(); // Предотвращаем всплытие клика
        setOpenMenuId((prevId) => (prevId === id ? null : id)); // Переключаем состояние
    };

    // Закрытие меню при клике вне карточки
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (!e.target.closest(".box-item")) {
                setOpenMenuId(null);
            }
        };

        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [setOpenMenuId]);

    // Функция для удаления коробки
    const handleBoxDelete = async (e) => {
        const result = await handleDelete({
            e,
            endpoint: USER_ENDPOINTS.POST_DELETE_BOX,
            params: { box_hash: box.box_hash },
            setError,
            setLoading,
            successRoute: `/dashboard`,
        });

        if (result) {
            // Если удаление прошло успешно, обновляем состояние и скрываем карточку
            setIsDeleted(true);
            updateState((prevBoxes) => prevBoxes.filter((item) => item.box_hash !== box.box_hash));
        }
    };

    if (isDeleted) return null; // Если карточка удалена, не рендерим ее

    const menuOptions = [
        {
            icon: "ic_24_pen",
            text: "Редактировать",
            onClick: () => {
                navigate(`/dashboard/${box.space_hash}/${box.box_hash}/editBox`, {
                    state: {
                        boxName: box.name,
                        boxDescription: box.description,
                        isPrivate: box.is_private,
                    },
                });
                setOpenMenuId(null); // Закрываем меню после клика
            },
        },
        {
            icon: "ic_24_trash",
            text: "Удалить",
            onClick: handleBoxDelete,
        },
    ];

    return (
        <div
            onClick={() => {
                navigate(`/dashboard/${box.space_hash}/${box.box_hash}`, {
                    state: {
                        boxName: box.name,
                        boxDescription: box.description,
                    },
                });
            }}
            className="box-item"
        >
            <div className="box-item__header">
                <ReactSVG src={res.icons["ic_24_box"]} className="box-item__icon" />
                {box.name}
            </div>
            <div className="box-item__actions">
                {box.is_private === 0 ? (
                    <ReactSVG src={res.icons["ic_24_lock_off"]} className="box-item__icon" />
                ) : null}
                <UI.Button
                    type="secondary"
                    icon="ic_24_dots_vertical"
                    size="medium"
                    className="box-item__menu-btn"
                    onClick={(e) => toggleMenu(e, box.box_hash)}
                />
            </div>

            <UI.DropdownMenu
                isOpen={openMenuId === box.box_hash}
                onClose={() => setOpenMenuId(null)}
                options={menuOptions}
            />
        </div>
    );
};

export default BoxCard;