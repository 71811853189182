// src/components/atoms/card/spaceCard.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as UI from "../../ui";
import res from "../../../resources/resources";
import { ReactSVG } from "react-svg";
import handleDelete from "../../../utils/misc/handleDelete";
import { ENDPOINTS as USER_ENDPOINTS } from "../../../utils/requests/e_spaces";

const SpaceCard = ({ space, openMenuId, setOpenMenuId, setError, setLoading, updateState }) => {
    const navigate = useNavigate();
    const [isDeleted, setIsDeleted] = useState(false);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (!e.target.closest(".space-item__container")) {
                setOpenMenuId(null);
            }
        };

        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [setOpenMenuId]);

    const toggleMenu = (e, id) => {
        e.stopPropagation();
        setOpenMenuId((prevId) => (prevId === id ? null : id));
    };

    const handleSpaceDelete = async (e) => {
        const result = await handleDelete({
            e,
            endpoint: USER_ENDPOINTS.POST_DELETE_SPACE,
            params: { space_hash: space.space_hash },
            setError,
            setLoading,
            successRoute: `/dashboard`,
            navigate,
        });

        if (result) {
            setIsDeleted(true);
            updateState((prevSpaces) => prevSpaces.filter((item) => item.space_hash !== space.space_hash));
        } else {
            setError("Ошибка при удалении пространства");
        }
    };

    if (isDeleted) return null;

    const menuOptions = [
        {
            icon: "ic_24_pen",
            text: "Редактировать",
            onClick: () => {
                navigate(`/dashboard/${space.space_hash}/editSpace`, {
                    state: {
                        spaceName: space.name,
                        isPrivate: space.is_private,
                    },
                });
                setOpenMenuId(null); // Закрываем меню после клика
            },
        },
        {
            icon: "ic_24_trash",
            text: "Удалить",
            onClick: handleSpaceDelete,
        },
    ];

    return (
        <div
            className="space-item__container"
            onClick={() => navigate(`/dashboard/${space.space_hash}`, {
                state: {
                    spaceName: space.name,
                    isPrivate: space.is_private,
                },
            })}
        >
            <div className="space-item__header">
                <ReactSVG src={res.icons["ic_24_garage"]} className="space-item__icon" />
                {space.name}
            </div>
            <div className="space-item__actions">
                {space.is_private === 0 && (
                    <ReactSVG src={res.icons["ic_24_lock_off"]} className="space-item__icon" />
                )}
                <UI.Button
                    type="secondary"
                    icon="ic_24_dots_vertical"
                    size="medium"
                    className="space-item__menu-button"
                    onClick={(e) => toggleMenu(e, space.space_hash)}
                />
            </div>

            <UI.DropdownMenu
                isOpen={openMenuId === space.space_hash}
                onClose={() => setOpenMenuId(null)}
                options={menuOptions}
            />
        </div>
    );
};

export default SpaceCard;