import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_spaces";
import request from "../../utils/requests/requests";
import * as UI from "../../components/ui";
import UserHeader from "../../components/block/header/header";
 
const CreateNewItemInSpace = () => {
    const navigate = useNavigate();
    const { spaceHash } = useParams();
    const [formData, setFormData] = useState({
        itemName: "",
        description: "",
        image: null,
        isPrivate: false, // Добавляем приватность
    });
    const [imagePreview, setImagePreview] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    // Обновление полей формы
    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [id]: type === "checkbox" ? checked : value,
        }));
    };

    // Обработка выбора изображения
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData((prev) => ({ ...prev, image: file }));

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setImagePreview(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.itemName) {
            setError("Введите название предмета");
            return;
        }

        setError("");
        setLoading(true);

        try {
            const payload = new FormData();
            payload.append("name", formData.itemName);
            payload.append("description", formData.description);
            payload.append("space_hash", spaceHash);
            payload.append("is_private", formData.isPrivate); // Передаем значение приватности
            if (formData.image) payload.append("image", formData.image);

            const response = await request(
                "POST",
                USER_ENDPOINTS.POST_CREATE_ITEM,
                payload,
                false,
                true
            );

            if (response.status === "success") {
                navigate(`/dashboard/${spaceHash}`);
            } else {
                setError(response.message || "Ошибка создания предмета");
            }
        } catch (err) {
            setError("Произошла ошибка, попробуйте позже");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <UserHeader />
            {error &&  <UI.ToastNotification status="error" message={error} />}
            <div className="navigate">
            <div className="go-back">
            <UI.Button
                icon="ic_24_arrow_left"
                type="secondary"
                onClick={() => navigate(-1)} // Оборачиваем navigate в функцию
                className="go-back-btn"
            />
                <h3>Добавить предмет в пространство</h3>
            </div>
        </div>


            <div className="wrapper" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <form className="form" onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {imagePreview && (
                        <div style={{ marginBottom: "15px", textAlign: "center" }}>
                        <img
                                src={imagePreview}
                                alt="Item"
                                style={{
                                    width: "256px",
                                    height: "288px",
                                    borderRadius: "20px",
                                    marginBottom: "10px",
                                }}
                            />
                        </div>
                    )}
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "15px"}}>
                        
                        <UI.Input
                            id="itemName"
                            label="Название предмета"
                            value={formData.itemName}
                            onChange={handleChange}
                            errorMessage={error && "Название предмета обязательно"}
                        />
                        <UI.TextArea
                            id="description"
                            label="Описание предмета"
                            value={formData.description}
                            onChange={handleChange}
                        />
                        <UI.FileInput
                            id="image"
                            label="Загрузить изображение"
                            accept=".jpg,.jpeg,.png"
                            onChange={handleImageChange}
                        />
                        <UI.CheckBox
                            id="isPrivate"
                            label="Сделать предмет приватным?"
                            checked={formData.isPrivate}
                            onChange={handleChange}
                        />
                        <div className="button-container">
                            <UI.Button
                                width="100%"
                                text={loading ? "Создание..." : "Создать"}
                                onClick={handleSubmit}
                            />
                            <UI.Button
                                width="100%"
                                type="secondary"
                                text="Назад"
                                onClick={() => navigate(`/dashboard/${spaceHash}`)}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateNewItemInSpace;