// src/components/organisms/header/layout/success.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import handleExit from "../../../../utils/exit/Exit"; // Функция выхода
import SearchEngine from "../../../elements/search/search"; // Поисковая строка
import res from "../../../../resources/resources"; // Ресурсы
import * as UI from "../../../ui"; // UI компоненты
import { ReactSVG } from 'react-svg'; // Импортируем компонент ReactSVG
const Success = ({ userInfo }) => {
    const navigate = useNavigate();
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    // Закрытие меню при клике вне элемента
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest(".user-container")) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => document.removeEventListener("click", handleClickOutside);
    }, []);

    return (
        <div className="user-header">
            <Link className="logo" to="/">Boxyfox</Link>
            <SearchEngine />

            <div className="user-container">
                <div className="user-avatar-container" onClick={() => setDropdownVisible((prev) => !prev)}>
                <ReactSVG 
                    onClick={(e) => {
                        e.stopPropagation(); // Останавливаем всплытие события
                        setDropdownVisible((prev) => !prev); // Меняем состояние видимости меню
                    }}
                    src={isDropdownVisible ? res.icons["ic_24_arrow_up"] : res.icons["ic_24_arrow_down"]} 
                    className="icon-path"
                    />
                <img
                    className="user-avatar"
                    src={userInfo.user.avatar || res.illustrations["ill_dummy_profile"]}
                    alt="User Avatar"
                />
                </div>

                {/* Выпадающее меню */}
                <div className={`user-info-dropdown ${isDropdownVisible ? "visible" : ""}`}>
                    <div className="user-info">
                        <div className="user-name-email">
                            <div className="user-name">{userInfo.user.name || "Загадочный лис!"}</div>
                            <div className="user-email">{userInfo.user.email}</div>
                        </div>
                 

                        <div className="user-counts">
                            <div className="count">
                                <ReactSVG src={res.icons['ic_24_garage']} className="icon-path"/> {userInfo.counts.spaces}
                            </div>
                            <div className="count">
                                <ReactSVG src={res.icons['ic_24_box']} className="icon-path"/> {userInfo.counts.boxes}
                            </div>
                            <div className="count">
                                <ReactSVG src={res.icons['ic_24_label']} className="icon-path"/>{userInfo.counts.items}
                            </div>
                        </div>
                    </div>
                    <div className="user-actions">
                        <UI.ButtonList
                            icon="ic_24_card"
                            size="medium"
                            to="/dashboard/subscription"
                            type="secondary"
                            text={`Моя подписка ${userInfo.subscription.name}`}
                        />
                        <UI.ButtonList
                            icon="ic_24_bill"
                            type="secondary"
                            text="История операций"
                            size="medium"
                            to="/dashboard/subscription/history"
                            className="create-btn"
                        />
                        <UI.ButtonList
                            icon="ic_24_coupon"
                            type="secondary"
                            text="Промокоды"
                            size="medium"
                            to="/dashboard/subscription/usePromocode"
                            className="create-btn"
                        />
                        <UI.ButtonList
                            icon="ic_24_pen"
                            type="secondary"
                            text="Редактировать профиль"
                            size="medium"
                            onClick={() => {
                                navigate(`/dashboard/editUser`, {
                                    state: {
                                        name: userInfo.user.name,
                                        email: userInfo.user.email,
                                    },
                                });
                            }}
                            className="create-btn"
                        />
                        <UI.ButtonList
                            type="secondary"
                            text="Выход"
                            size="medium"
                            icon="ic_24_exit"
                            onClick={handleExit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Success;