import React, { useState, useEffect, useRef } from "react";
import * as UI from "../../ui";

/**
 * Универсальный компонент DropdownMenu
 * @param {boolean} isOpen - Открыт ли дропдаун
 * @param {Function} onClose - Функция для закрытия дропдауна
 * @param {Array} options - Массив кнопок [{ icon, text, onClick }]
 */
const DropdownMenu = ({ isOpen, onClose, options }) => {
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [position, setPosition] = useState("down"); // 'down' или 'up'
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setOverlayVisible(true);

            // Проверяем, влезает ли дропдаун в экран
            if (dropdownRef.current) {
                const rect = dropdownRef.current.getBoundingClientRect();
                const spaceBelow = window.innerHeight - rect.bottom;
                const spaceAbove = rect.top;

                console.log("🟢 Дропдаун открыт");
                console.log(`🔽 Свободное место ВНИЗУ: ${spaceBelow}px`);
                console.log(`🔼 Свободное место ВВЕРХУ: ${spaceAbove}px`);
                console.log(`📏 Высота дропдауна: ${rect.height}px`);

                // Если внизу места мало, а сверху больше — открываем вверх
                if (spaceBelow < rect.height && spaceAbove > rect.height) {
                    console.log("🔼 Переключаем дропдаун ВВЕРХ");
                    setPosition("up");
                } else {
                    console.log("🔽 Дропдаун остается ВНИЗУ");
                    setPosition("down");
                }
            }
        } else {
            setOverlayVisible(false);
        }
    }, [isOpen]);

    const handleClose = () => {
        console.log("❌ Закрытие дропдауна");
        setOverlayVisible(false);
        onClose();
    };

    const handleOverlayClick = (e) => {
        e.stopPropagation();
        handleClose();
    };

    if (!isOpen) return null;

    return (
        <>
            <div className={`action-overlay ${isOverlayVisible ? "" : "hidden"}`} onClick={handleOverlayClick}></div>
            <div
                ref={dropdownRef}
                className={`dropdown-menu ${position === "up" ? "dropdown-up" : "dropdown-down"}`}
                onClick={(e) => e.stopPropagation()}
            >
                {options.map(({ icon, text, onClick }, index) => (
                    <UI.ButtonList
                        key={index}
                        icon={icon}
                        text={text}
                        type="secondary"
                        size="medium"
                        onClick={() => {
                            console.log(`✅ Выбрано действие: ${text}`);
                            onClick();
                            handleClose();
                        }}
                    />
                ))}
            </div>
        </>
    );
};

export default DropdownMenu;