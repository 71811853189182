import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div style={styles.container}>
      {/* Hero Section */}
      <header style={styles.header}>
        <h1 style={styles.title}>Организуйте свои вещи легко и удобно с BoxyFox</h1>
        <p style={styles.subtitle}>Создавайте пространства, храните вещи в коробках, сканируйте QR-коды и находите все в один клик!</p>
        <Link to="/login" style={styles.button}>Начать бесплатно</Link>
      </header>

      {/* Преимущества BoxyFox */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Зачем выбирать BoxyFox?</h2>
        <div style={styles.featureList}>
          <div style={styles.feature}>
            <h3>Умная организация</h3>
            <p>Создавайте пространства и храните вещи в коробках, всё на своих местах.</p>
          </div>
          <div style={styles.feature}>
            <h3>QR-коды</h3>
            <p>Легко находите вещи, сканируя QR-коды, без открытия коробок.</p>
          </div>
          <div style={styles.feature}>
            <h3>Умный поиск</h3>
            <p>Находите вещи по запросу, даже если не помните, где они находятся.</p>
          </div>
          <div style={styles.feature}>
            <h3>Машинное зрение</h3>
            <p>Приложение автоматически заполняет описание вещей на основе их изображений.</p>
          </div>
        </div>   
      </section>

      {/* Как это работает */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Как BoxyFox помогает организовать ваше пространство</h2>
        <div style={styles.featureList}>
          <div style={styles.feature}>
            <h3>Шаг 1: Создайте пространство</h3>
            <p>Легко создайте любое пространство (кладовку, склад) для организации вещей.</p>
          </div>
          <div style={styles.feature}>
            <h3>Шаг 2: Добавьте коробки</h3>
            <p>Добавляйте коробки с вещами и получайте QR-коды для каждой коробки или пространства.</p>
          </div>
          <div style={styles.feature}>
            <h3>Шаг 3: Сканируйте и находите вещи</h3>
            <p>Сканируйте QR-коды и легко находите, что и где находится. Или используйте поиск по естественному языку!</p>
          </div>
        </div>
      </section>

      {/* Тарифы */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Выберите тариф, который подходит вам</h2>
        <div style={styles.planList}>
          <div style={styles.plan}>
            <h3>Free</h3>
            <p>До 1 пространства, 3 коробки и 10 вещей. Бесплатно.</p>
          </div>
          <div style={styles.plan}>
            <h3>Starter</h3>
            <p>До 3 пространств, 10 коробок и 50 вещей. $5 в месяц.</p>
          </div>
          <div style={styles.plan}>
            <h3>Pro</h3>
            <p>До 10 пространств, 50 коробок и 200 вещей. $15 в месяц.</p>
          </div>
        </div>
        <p style={styles.planNote}>Выберите план и начните организовывать пространство прямо сейчас!</p>
      </section>

      {/* Призыв к действию */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Готовы начать?</h2>
        <p style={styles.motivationalText}>Присоединяйтесь к BoxyFox и начните организовывать пространство для вещей уже сегодня!</p>
        <Link to="/login" style={styles.button}>Зарегистрироваться</Link>
      </section>

      {/* Полезные ссылки */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Полезные ссылки</h2>
        <ul style={styles.linkList}>
          <li><Link to="/about" style={styles.link}>О нас</Link></li>
          <li><Link to="/privacy" style={styles.link}>Политика конфиденциальности</Link></li>
          <li><Link to="/rules" style={styles.link}>Правила использования</Link></li>
          <li><Link to="/contact" style={styles.link}>Контакты</Link></li>
        </ul>
      </section>

      {/* Футер */}
      <footer style={styles.footer}>
        <p>© 2025 BoxyFox. Все права защищены.</p>
      </footer>
    </div>
  );
};

// Стилизация страницы
const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    color: '#333',
    margin: 0,
    padding: 0,
    textAlign: 'center',
  },
  header: {
    backgroundColor: '#ff6347',
    padding: '50px 0',
    color: 'white',
  },
  title: {
    fontSize: '3rem',
    margin: '0',
  },
  subtitle: {
    fontSize: '1.5rem',
    marginTop: '10px',
  },
  button: {
    display: 'inline-block',
    marginTop: '20px',
    padding: '15px 30px',
    backgroundColor: '#ffffff',
    color: '#ff6347',
    fontSize: '1.2rem',
    borderRadius: '5px',
    textDecoration: 'none',
    transition: 'background-color 0.3s ease',
  },
  section: {
    padding: '50px 20px',
    backgroundColor: '#fff',
  },
  sectionTitle: {
    fontSize: '2rem',
    marginBottom: '20px',
  },
  featureList: {
    display: 'flex',
    justifyContent: 'center',
    gap: '30px',
    flexWrap: 'wrap',
    padding: '20px',
  },
  feature: {
    backgroundColor: '#ff6347',
    color: 'white',
    padding: '20px',
    borderRadius: '10px',
    width: '250px',
    textAlign: 'left',
  },
  planList: {
    display: 'flex',
    justifyContent: 'center',
    gap: '30px',
    flexWrap: 'wrap',
    padding: '20px',
  },
  plan: {
    backgroundColor: '#ff6347',
    color: 'white',
    padding: '20px',
    borderRadius: '10px',
    width: '250px',
    textAlign: 'left',
  },
  planNote: {
    marginTop: '20px',
    fontSize: '1.2rem',
    color: '#ff6347',
  },
  motivationalText: {
    fontSize: '1.5rem',
    marginBottom: '20px',
    fontStyle: 'italic',
  },
  footer: {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    fontSize: '0.9rem',
  },
  linkList: {
    listStyleType: 'none',
    padding: 0,
  },
  link: {
    color: '#ff6347',
    textDecoration: 'none',
    fontSize: '1.2rem',
    display: 'block',
    margin: '10px 0',
  },
};

export default LandingPage;