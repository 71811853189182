// src/pages/EditExisting/EditExistingSpace.js
import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_spaces";
import request from "../../utils/requests/requests";
import * as UI from "../../components/ui"; // UI компоненты
import UserHeader from "../../components/block/header/header";
 
/**
 * EditExistingSpace - компонент для редактирования существующего пространства
 * 
 * Отображает форму с полем ввода имени пространства, текстовым полем для описания пространства,
 * а также чекбокс для приватности пространства, и две кнопки: "Сохранить изменения" и "Назад".
 * 
 * При отправке формы компонент отправляет POST-запрос на сервер с именем пространства, описанием и статусом приватности.
 * Если запрос успешен, компонент перенаправляет пользователя на страницу пространства с полученным space_hash.
 * Если запрос неудачен, компонент отображает сообщение об ошибке.
 */
const EditExistingSpace = () => {
    const { spaceHash } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const { spaceName, isPrivate } = location.state || {};

    const [space_name, setName] = useState(spaceName || "");
    const [isPrivateState, setIsPrivateState] = useState(isPrivate || false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    
    const searchParams = new URLSearchParams(location.search);
    const fromSpace = searchParams.get("fromSpace");

    const goBack = (e) => {
        e.preventDefault();
        if (fromSpace === "1") {
            navigate(`/dashboard/${spaceHash}/`);
          } else {
            navigate(`/dashboard/`);
          }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!space_name) {
            setError("Введите название пространства");
            return;
        }

        setError("");
        setLoading(true);

        try {
            const response = await request(
                "POST",
                `${USER_ENDPOINTS.POST_EDIT_SPACE}`,
                { 
                    name: space_name,
                    space_hash: spaceHash,
                    is_private: isPrivateState,
                },
                false,
                true
            );

            if (response.status === "success") {
                navigate(`/dashboard/${spaceHash}`);
            } else {
                setError(response.message || "Ошибка обновления пространства");
            }
        } catch (err) {
            setError("Что-то пошло не так, попробуйте позже");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <UserHeader />
            {error &&  <UI.ToastNotification status="error" message={error} />}
            <div className="navigate">
                <div className="go-back">
                    <UI.Button
                    icon="ic_24_arrow_left"
                    type="secondary"
                    onClick={goBack} // Используем goBack для возврата
                    className="go-back-btn"
                    />
                    <h3>Редактировать пространство</h3>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="wrapper">
                <form onSubmit={handleSubmit} className="form">
                    
                        <UI.Input
                            id="spaceName"
                            label="Название пространства"
                            value={space_name}
                            onChange={(e) => setName(e.target.value)}
                            errorMessage={error && "Название пространства обязательно"}
                        />
                   
                        <UI.CheckBox
                            id="isPrivate"
                            label="Скрытое пространство"
                            checked={isPrivateState}
                            onChange={(e) => setIsPrivateState(e.target.checked)}
                        />
                    <div className="button-container">
                        <UI.Button
                            width="100%"
                            text={loading ? "Загрузка..." : "Сохранить"}
                            onClick={handleSubmit}
                        />
                        <UI.Button
                            width="100%"
                            type="secondary"
                            text="Назад" 
                            onClick={goBack}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditExistingSpace;