import React, { useState, useEffect, useRef } from "react";
import { ENDPOINTS as USER_ENDPOINTS } from "../../../utils/requests/e_ai";
import request from "../../../utils/requests/requests";
import Success from "./layout/success";
import Loading from "./layout/loading";
import Empty from "./layout/empty";
import Error from "./layout/error";
import * as UI from "../../ui";

const SearchEngine = () => {
  const [searchQuery, setSearchQuery] = useState(""); // Значение поиска
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(""); // Дебаунс
  const [timeoutId, setTimeoutId] = useState(null); // ID таймера для дебаунса
  const [searchResults, setSearchResults] = useState(null); // Результаты поиска
  const [errorMessage, setErrorMessage] = useState(""); // Сообщение об ошибке
  const [loading, setLoading] = useState(false); // Статус загрузки
  const [isResultsVisible, setIsResultsVisible] = useState(false); // Флаг видимости результатов поиска

  const searchRef = useRef(null); // Ref для области поиска

  // Обработчик изменения ввода
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    // Показать loading при вводе текста
    setLoading(true);

    if (timeoutId) {
      clearTimeout(timeoutId); // Очищаем предыдущий таймер
    }

    // Устанавливаем новый таймер для дебаунса
    const newTimeoutId = setTimeout(() => {
      setDebouncedSearchQuery(value);
    }, 1000); // Задержка 1 секунда
    setTimeoutId(newTimeoutId);

    // Показываем результаты только при вводе текста
    setIsResultsVisible(true);
  };

  // Обработчик клика по input
  const handleInputFocus = () => {
    if (searchQuery.length >= 4) {
      setIsResultsVisible(true); // Показываем результаты поиска при фокусе
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
        event.target.blur(); // Скрываем клавиатуру
    }
  };

  // Функция для получения результатов поиска
  const fetchSearchResults = async (query) => {
    try {
      // Запрос не отправляется, если менее 4 символов
      if (query.length < 4) {
        setLoading(false);
        return;
      }
      const data = await request("GET", USER_ENDPOINTS.GET_SEARCH(query), false, true);

      if (data.status === "success") {
        setSearchResults(data.results);
        setErrorMessage(""); // Очищаем ошибку, если запрос успешен
      } else if (data.status === "error") {
        setErrorMessage(data.message);
        setSearchResults(null);
      }
    } catch (error) {
      setErrorMessage("Произошла ошибка при поиске. Попробуйте позже.");
      setSearchResults(null);
    } finally {
      setLoading(false);
    }
  };

  // Следим за изменениями в debouncedSearchQuery
  useEffect(() => {
    if (debouncedSearchQuery.length >= 4) {
      fetchSearchResults(debouncedSearchQuery); // Выполняем поиск, если длина запроса >= 4
    } else {
      setSearchResults(null); // Если запрос менее 4 символов, очищаем результаты
      setErrorMessage(""); // Очищаем ошибку
      setLoading(false); // Останавливаем загрузку
    }
  }, [debouncedSearchQuery]);

  // Закрытие результатов поиска при клике вне области
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setIsResultsVisible(false); // Скрываем результаты поиска
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Блокируем прокрутку при показе оверлея
  useEffect(() => {
    if (isResultsVisible) {
      document.body.classList.add('locked'); // Блокируем прокрутку
    } else {
      document.body.classList.remove('locked'); // Разблокируем прокрутку
    }

    return () => document.body.classList.remove('locked'); // Очищаем класс при размонтировании компонента
  }, [isResultsVisible]);

  return (
    <>
    <div className={isResultsVisible && searchQuery.length >= 1 ? "search-overlay" : "search-overlay hidden"}/>
    <div className="search-engine" ref={searchRef}>
      <div className="search-bar">
        <input
          type="search"
          enterKeyHint="search"
          placeholder="Где мои ... ?"
          value={searchQuery}
          onChange={handleSearchChange}
          onFocus={handleInputFocus} // Показываем результаты при фокусе
          className="search-button"
          onKeyDown={handleKeyDown}
        />
        <UI.Button icon="ic_24_search" />
      </div>
      {/* Результаты поиска */}
      {searchQuery.length >= 4 && isResultsVisible && (
        <div className="search-results">
          {loading ? (
            <Loading />
          ) : errorMessage ? (
            <Error errorMessage={errorMessage} />
          ) : searchResults ? (
            searchResults.spaces.length === 0 &&
            searchResults.boxes.length === 0 &&
            searchResults.items.length === 0 ? (
              <Empty />
            ) : (
              <Success searchResults={searchResults} />
            )
          ) : (
            <Loading />
          )}
        </div>
      )}
    </div>
    </>
  );
};

export default SearchEngine;