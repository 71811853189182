import React, { useEffect, useState } from "react";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_spaces";
import { useNavigate, useParams } from "react-router-dom";
import request from "../../utils/requests/requests";
import UserHeader from "../../components/block/header/header";
import * as UI from "../../components/ui";
import { useGlobalContext } from "../../utils/context/globalStates";
import BoxCard from "../../components/elements/card/boxCard";
import ItemCard from "../../components/elements/card/itemCard";
import handleDelete from "../../utils/misc/handleDelete"; // Импортируем функцию удаления

const GetBoxes = () => {
    const navigate = useNavigate();
    const { spaceHash } = useParams();
    const { boxInfo, setBoxInfo } = useGlobalContext(); // Получаем глобальные состояния

    const [spaceData, setSpaceData] = useState(null);
    const [items, setItems] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [openMenuId, setOpenMenuId] = useState(null); // Управляем состоянием открытого меню
    const [isDropdownOpen, setDropdownOpen] = useState(false); // Состояние для дропдауна
        
    // Удаление пространства
    const handleSpaceDelete = async (e, spaceHash) => {
        // Вызов handleDelete для удаления пространства
        const result = await handleDelete({
            e,
            endpoint: USER_ENDPOINTS.POST_DELETE_SPACE,
            params: { space_hash: spaceHash },
            successRoute: `/dashboard/`,
            confirmMessage: "Вы уверены, что хотите удалить это пространство? Это действие нельзя будет отменить.",
            setError,
            setLoading,
            navigate,
        });

        navigate(`/dashboard/`);
    };
    

    useEffect(() => {
        const fetchSpaceData = async () => {
            setLoading(true);
            setError("");

            try {
                const data = await request("GET", USER_ENDPOINTS.GET_BOXES(spaceHash), false, true);
                if (data.status === "success") {
                    setSpaceData(data);
                    setBoxInfo(data.boxes || []);
                    setItems(data.items.filter(item => item.box_hash === null) || []);
                } else {
                    setError(data.message || "Failed to load space");
                }
            } catch (err) {
                setError("An error occurred, please try again later");
                console.error("Error:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchSpaceData();
    }, [spaceHash]);

    return (
        <div className="container">
            <title>{spaceData ? spaceData.space_name : "🤔"}</title>
            <UserHeader />
            <div className="navigate">
                <div className="go-back">
                    <UI.Button icon="ic_24_arrow_left" type="secondary" to={`/dashboard`} className="go-back-btn" />
                    {spaceData ? <h3>{spaceData.space_name}</h3> : <h3>🤔</h3>}
                </div>

                <div className="actions">
                    <UI.Button
                        icon="ic_24_dots_vertical"
                        type="secondary"
                        onClick={() => setDropdownOpen((prev) => !prev)}
                    />
                    

                    {isDropdownOpen && (
                        <UI.DropdownMenu
                            isOpen={isDropdownOpen}
                            onClose={() => setDropdownOpen(false)}
                            options={[
                                {
                                    icon: "ic_24_plus",
                                    text: "Добавить предмет",
                                    onClick: () => navigate(`/dashboard/${spaceHash}/createItem`),
                                },
                                {
                                    icon: "ic_24_qr",
                                    text: "Показать QR",
                                    onClick: () => navigate(`/dashboard/${spaceHash}/showSpaceQR`, {
                                    state: { spaceName: spaceData.space_name },
                                })
                                },


                                {
                                    icon: "ic_24_pen",
                                    text: "Редактировать пространство",
                                    onClick: () => {
                                        navigate(`/dashboard/${spaceHash}/editSpace?fromSpace=1`, {
                                            state: {
                                                spaceName: spaceData.space_name,
                                                isPrivate: spaceData.is_private,
                                            },
                                        });
                                    },
                                },
                                {
                                    icon: "ic_24_trash",
                                    text: "Удалить пространство",
                                    onClick: () => handleSpaceDelete(null, spaceHash),
                                },
                                
                                
                            ]}
                        />
                    )}

                    
                    
                    <UI.Button
                        text="Добавить коробку"
                        to={`/dashboard/${spaceHash}/createBox`}
                        className="create-btn"
                    />
                </div>
            </div>

            {/* {loading && <div className="loading">🤔</div>} */}
            {error && <div className="error">{error}</div>}

            {boxInfo.length > 0 ? (
                <ul className="boxes-list">
                    {boxInfo.map((box) => (
                        <BoxCard
                            key={box.box_hash}
                            box={box}
                            openMenuId={openMenuId}
                            setOpenMenuId={setOpenMenuId}
                            setError={setError}
                            setLoading={setLoading}
                            updateState={setBoxInfo}
                        />
                    ))}
                </ul>
            ) : (
                <div className="no-boxInfo">No box found</div>
            )}

            {items.length > 0 && (
                <ul className="boxes-list">
                    {items.map((item) => (
                        <ItemCard
                            key={item.item_hash}
                            item={item}
                            openMenuId={openMenuId}
                            setOpenMenuId={setOpenMenuId}
                            setError={setError}
                            setLoading={setLoading}
                            updateState={setBoxInfo}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default GetBoxes;