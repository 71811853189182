import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_spaces"; // Эндпоинты
import request from "../../utils/requests/requests"; // Функция для отправки запросов
import * as UI from "../../components/ui"; // UI компоненты
import UserHeader from "../../components/block/header/header";
 const EditExistingItem = () => {
    const { spaceHash, boxHash, itemHash } = useParams(); // Получаем параметры из URL
    const navigate = useNavigate(); // Для навигации
    const location = useLocation(); // Получаем состояние из Link

    const [itemName, setItemName] = useState(""); // Для хранения имени предмета
    const [description, setDescription] = useState(""); // Для хранения описания
    const [image, setImage] = useState(null); // Для хранения изображения
    const [imagePreview, setImagePreview] = useState(null); // Для хранения предварительного просмотра изображения
    const [isPrivate, setIsPrivate] = useState(false); // Для состояния чекбокса
    const [error, setError] = useState(""); // Для отображения ошибок
    const [loading, setLoading] = useState(false); // Для состояния загрузки


    const searchParams = new URLSearchParams(location.search);
    const fromBox = searchParams.get("fromBox");
    // Функция для возврата на предыдущую страницу
  const goBack = (e) => {
    e.preventDefault();  // Предотвращаем действие по умолчанию (например, submit)

    if (fromBox === "1") {
      navigate(`/dashboard/${spaceHash}/${boxHash}`);
    } else {
      navigate(`/dashboard/${spaceHash}/`);
    }
  };
    
    useEffect(() => {
        // Если переданы данные в state, используем их для инициализации состояний
        if (location.state) {
            setItemName(location.state.name || "");
            setDescription(location.state.description || "");
            setImagePreview(location.state.imageUrl ? `https://boxyfox.ru/uploads/${location.state.imageUrl}` : null);
            setIsPrivate(location.state.isPrivate || false); // Инициализируем чекбокс
        }
    }, [location.state]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file); // Сохраняем выбранное изображение

        // Создаем локальный URL для предпросмотра изображения
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result); // Устанавливаем URL изображения в состоянии
        };
        if (file) {
            reader.readAsDataURL(file); // Читаем изображение как DataURL
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Проверка на пустое имя предмета
        if (!itemName) {
            setError("Введите название предмета");
            return;
        }

        setError(""); // Очистка ошибки
        setLoading(true); // Включаем загрузку

        const formData = new FormData();
        formData.append("space_hash", spaceHash); // Передаем space_hash
        formData.append("box_hash", boxHash); // Передаем box_hash
        formData.append("item_hash", itemHash); // Передаем item_hash
        formData.append("name", itemName); // Новое имя предмета
        formData.append("description", description); // Новое описание предмета
        formData.append("is_private", isPrivate); // Добавляем значение чекбокса
        if (image) formData.append("image", image); // Добавляем изображение, если оно выбрано

        try {
            // Отправляем POST-запрос на сервер для редактирования предмета
            const response = await request(
                "POST",
                USER_ENDPOINTS.POST_EDIT_ITEM, // Используем правильный эндпоинт
                formData,
                false, // Не используем JSON
                true // Для включения авторизации
            );

            if (response.status === "success") {
                // Перенаправляем на страницу предмета после успешного обновления
                navigate(`/dashboard/${spaceHash}/${boxHash}`);
            } else {
                setError(response.message || "Ошибка обновления предмета");
            }
        } catch (err) {
            setError("Что-то пошло не так, попробуйте позже");
            console.error("Error:", err);
        } finally {
            setLoading(false); // Отключаем загрузку
        }
    };

    return (
        <div className="container">
            <UserHeader />
            {error &&  <UI.ToastNotification status="error" message={error} />}
            <div className="navigate">
            <div className="go-back">
                <UI.Button
                icon="ic_24_arrow_left"
                type="secondary"
                onClick={goBack} // Используем goBack для возврата
                className="go-back-btn"
                />
                <h3>Редактировать предмет</h3>
            </div>
        </div>
  
      
            <div className="wrapper" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <form className="form" onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {imagePreview && (
                        <div style={{ marginBottom: "15px" }}>
                            <img
                                src={imagePreview}
                                alt="Item"
                                style={{
                                    width: "256px",
                                    height: "288px",
                                    borderRadius: "20px",
                                    marginBottom: "10px",
                                }}
                            />
                        </div>
                    )}
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "15px"}}>
                        <UI.Input
                            id="itemName"
                            label="Название предмета"
                            value={itemName}
                            onChange={(e) => setItemName(e.target.value)}
                            errorMessage={error && "Название предмета обязательно"}
                        />
                        <UI.TextArea
                            id="description"
                            label="Описание предмета"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            errorMessage={error && "Описание предмета обязательно"}
                        />
                        <UI.FileInput
                            id="image"
                            label="Загрузить изображение"
                            onChange={handleImageChange}
                        />
                        <UI.CheckBox
                            id="isPrivate"
                            label="Сделать предмет приватным?"
                            checked={isPrivate}
                            onChange={(e) => setIsPrivate(e.target.checked)}
                        />
                        <div className="button-container">
                            <UI.Button
                                width="100%"
                                text={loading ? "Загрузка..." : "Сохранить"}
                                onClick={handleSubmit}
                            />
                            <UI.Button
                                width="100%"
                                type="secondary"
                                text="Назад"
                                onClick={goBack}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditExistingItem;