import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_user";
import request from "../../utils/requests/requests";
import * as UI from "../../components/ui";
import UserHeader from "../../components/block/header/header";

/**
 * EditUser - компонент для редактирования данных пользователя
 */
const EditUser = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const { name: initialName, email: initialEmail } = location.state || {};

    useEffect(() => {
        if (initialName && initialEmail) {
            setName(initialName);
            setEmail(initialEmail);
        } else {
            setError("Данные пользователя отсутствуют");
        }
    }, [initialName, initialEmail]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password && password !== confirmPassword) {
            setError("Пароли не совпадают");
            return;
        }

        const updatedData = {
            ...(name && { name }),
            ...(email && { email }),
            ...(password && { password }),
        };

        if (Object.keys(updatedData).length === 0) {
            setError("Нет изменений для сохранения");
            return;
        }

        setError("");
        setLoading(true);

        try {
            const response = await request(
                "POST",
                USER_ENDPOINTS.POST_EDIT_USER,
                updatedData,
                false,
                true
            );

            if (response.status === "success") {
                setSuccessMessage(response.message || "Данные успешно обновлены!");
            } else {
                setError(response.message || "Не удалось обновить данные пользователя");
            }
        } catch (err) {
            setError("Произошла ошибка, попробуйте снова позже");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="container">
            <UserHeader />
            <UI.ToastNotification status="error" message={error} />
            <UI.ToastNotification status="success" message={successMessage} />
            <div className="navigate">
                <div className="go-back">
                    <UI.Button
                        icon="ic_24_arrow_left"
                        type="secondary"
                        onClick={goBack}
                        className="go-back-btn"
                    />
                    <h3>Редактировать пользователя</h3>
                </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="wrapper">
                <form className="form" onSubmit={handleSubmit}>
                    <UI.Input
                        id="name"
                        placeholder="Введите имя"
                        label="Имя"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        errorMessage={error && !name && "Имя обязательно"}
                    />
                    
                    <UI.Input
                        id="email"
                        label="Email"
                        type="email"
                        placeholder="Введите email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        errorMessage={error && !email && "Email обязателен"}
                    />
                    
                    <UI.Input
                        id="password"
                        label="Пароль"
                        type="password"
                        minLength="6"
                        placeholder="Введите новый пароль"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    
                    <UI.Input
                        id="confirmPassword"
                        label="Подтверждение пароля"
                        type="password"
                        minLength="6"
                        placeholder="Повторите новый пароль"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />


                    <div className="button-container">
                        <UI.Button
                            width="100%"
                            text={loading ? "Загрузка..." : "Сохранить"}
                            onClick={handleSubmit}
                        />
                        <UI.Button
                            width="100%"
                            type="secondary"
                            text="Назад"
                            onClick={goBack}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditUser;