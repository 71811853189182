import LandingPage from "../../pages/SimplePages/Landing";
import LoginPage from "../../pages/UserAuth/LoginPage";
import RegistrationPage from "../../pages/UserAuth/RegistrationPage";
import RecoverPage from "../../pages/UserAuth/RecoverPage";
import CheckVerificationPage from "../../pages/UserAuth/CheckVerification";
import EditUser from "../../pages/UserAuth/editUser";

import GetSpaces from "../../pages/GetData/GetSpaces";
import GetBoxes from "../../pages/GetData/GetBoxes";
import GetItems from "../../pages/GetData/GetItems"; // Импорт страницы коробки

import CreateNewSpace from "../../pages/CreateNew/CreateNewSpace";
import CreateNewItemInSpace from "../../pages/CreateNew/CreateNewItemInSpace";
import CreateNewBox from "../../pages/CreateNew/CreateNewBox";
import CreateNewItem from "../../pages/CreateNew/CreateNewItem";

import EditExistingSpace from "../../pages/EditExisting/EditExistingSpace";
import EditExistingBox from "../../pages/EditExisting/EditExistingBox";
import EditExistingItem from "../../pages/EditExisting/EditExistingItem";

import ShowBoxQR from "../../pages/QR/ShowBoxQR";
import ShowSpaceQR from "../../pages/QR/ShowSpaceQR";

import GetBoxesViewer from "../../pages/viewer/GetBoxesViewer";
import GetItemsViewer from "../../pages/viewer/GetItemsViewer";

import MySubscriptionPage from "../../pages/Subscription/SubscriptionStatusPage";
import SubscriptionHistoryPage from "../../pages/Subscription/SubscriptionHistoryPage";
import PromocodePage from "../../pages/Subscription/usePromocode";
import PaymentMethods from "../../pages/Subscription/PaymentMethodsPage";


import RulesPage from "../../pages/SimplePages/Rules";
import PrivacyPage from "../../pages/SimplePages/Privacy";

import AboutPage from "../../pages/SimplePages/About";
import CredentialPage from "../../pages/SimplePages/Credentials";

import NotFoundPage from "../../pages/NotFound/NotFound"; // Компонент для 404 страницы



const routes = [
    { path: "/", component: LandingPage },
    { path: "/login", component: LoginPage },
    { path: "/registration", component: RegistrationPage },
    { path: "/recover", component: RecoverPage },
    { path: "/check-verification", component: CheckVerificationPage },

    { path: "/dashboard", component: GetSpaces },
    { path: "/dashboard/editUser", component: EditUser },
    { path: "/dashboard/:spaceHash", component: GetBoxes },
    { path: "/dashboard/:spaceHash/:boxHash", component: GetItems },

    { path: "/dashboard/createSpace", component: CreateNewSpace },
    { path: "/dashboard/:spaceHash/createItem", component: CreateNewItemInSpace },

    { path: "/dashboard/:spaceHash/createBox", component: CreateNewBox },
    { path: "/dashboard/:spaceHash/:boxHash/createItem", component: CreateNewItem },

    { path: "/dashboard/:spaceHash/editSpace", component: EditExistingSpace },
    { path: "/dashboard/:spaceHash/:boxHash/editBox", component: EditExistingBox },
    { path: "/dashboard/:spaceHash/:boxHash/:itemHash/editItem", component: EditExistingItem },

    { path: "/dashboard/:spaceHash/:boxHash/showBoxQR", component: ShowBoxQR },
    { path: "/dashboard/:spaceHash/showSpaceQR", component: ShowSpaceQR },//показывает qr коды всех коробок и код пространства

    { path: "/viewer/:spaceHash/", component: GetBoxesViewer },//показывает все, что внутри пространства, если пространство публичное
    { path: "/viewer/:spaceHash/:boxHash", component: GetItemsViewer },//показывает все, что внутри коробки, если коробка публичная


    { path: "/dashboard/subscription", component: MySubscriptionPage }, //показывает мой тарифный план
    { path: "/dashboard/subscription/history", component: SubscriptionHistoryPage },//показывает историю покупок
    { path: "/dashboard/subscription/paymentMethods", component: PaymentMethods },//показывает историю покупок
    { path: "/dashboard/subscription/usePromocode", component: PromocodePage },//показывает историю покупок

    // { path: "/dashboard/profile", component: ProfilePage },//показывает мой профиль
    // { path: "/dashboard/profile/edit", component: EditProfilePage },//изменение моего профиля
    // { path: "/dashboard/settings", component: SettingsPage },//настройки

    // { path: "/support", component: SupportPage },//поддержка
    // { path: "/support/:supportID", component: ShowSupportPage },//страница статьи

    { path: "/rules", component: RulesPage },//правила использования сервиса
    { path: "/privacy", component: PrivacyPage },//политика конфиденциальности


    { path: "/about", component: AboutPage },//о нас
    { path: "/credentials", component: CredentialPage },//наши данные

    { path: "*", component: NotFoundPage }, // Обработка всех других путей
];

export default routes;