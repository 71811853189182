import React, { useState } from "react";

const Input = ({
  id,
  label,
  value,
  placeholder,
  onChange,
  type = "text",
  errorMessage,
  minLength,
  required,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="input-container">
      <input
        placeholder=" "  // Пустой placeholder для правильной работы лейбла
        type={type}
        id={id}
        value={value}
        minLength={minLength}
        required = {required}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={`input-field ${isFocused || value ? "focused" : ""} ${errorMessage ? "error-input" : ""}`}
        {...rest}
      />
      <label htmlFor={id} className="label">
        {label}
      </label>
      {errorMessage && (
        <div style={{ color: "red", marginTop: "5px" }}>{errorMessage}</div>
      )}
    </div>
  );
};

export default Input;