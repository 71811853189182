import React from "react";
import res from "../../../../resources/resources";

const Error = ({ errorMessage }) => (
  <div className="error-message">
      <img src={res.illustrations['ill_message_sent']} alt="Fox Not Found" />
    <p>{errorMessage}</p>
  </div>
);

export default Error;