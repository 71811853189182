import React from "react";

const Loading = () => (
  <div className="loading-message">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Loading;