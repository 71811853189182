import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import BASE_URL from "../../utils/constants/baseUrl"; // Импортируем базовый URL
import * as UI from "../../components/ui";
import UserHeader from "../../components/block/header/header";
const ShowBoxQR = () => {
    const location = useLocation();  // Получаем данные через useLocation
    const { boxName } = location.state || {};
    const { spaceHash, boxHash } = useParams(); // Извлекаем параметры маршрута
    const [qrData, setQrData] = useState("");
    const qrRef = useRef(null); // Ссылка на элемент QR-кода
    const navigate = useNavigate();
    useEffect(() => {
        // Формируем данные для QR-кода
        const qrContent = `https://www.boxyfox.ru/viewer/${spaceHash}/${boxHash}`; // Примерный URL, который будет зашифрован в QR-код
        setQrData(qrContent); // Устанавливаем данные для QR-кода
    }, [spaceHash, boxHash]);

    // Функция для сохранения QR-кода с подписью как картинки
    const saveQRCode = () => {
        if (qrRef.current) {
            const canvas = qrRef.current.querySelector("canvas");
            const imageUrl = canvas.toDataURL("image/png");

            // Создаем новый canvas для рисования текста
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                const canvasWithText = document.createElement("canvas");
                const ctx = canvasWithText.getContext("2d");

                // Настройки шрифта и максимальная ширина для текста
                const font = "20px Arial";
                const lineHeight = 24;
                const maxWidth = img.width - 40; // Отступы от краев

                // Функция для разбивания текста на строки
                const wrapText = (text, maxWidth, font) => {
                    const words = text.split(" ");
                    let lines = [];
                    let currentLine = "";

                    ctx.font = font;
                    for (let i = 0; i < words.length; i++) {
                        const testLine = currentLine + (currentLine ? " " : "") + words[i];
                        const testWidth = ctx.measureText(testLine).width;
                        if (testWidth > maxWidth) {
                            lines.push(currentLine);
                            currentLine = words[i];
                        } else {
                            currentLine = testLine;
                        }
                    }
                    lines.push(currentLine);
                    return lines;
                };

                // Разбиваем текст на строки
                const lines = wrapText(boxName, maxWidth, font);

                // Устанавливаем высоту канваса в зависимости от количества строк текста
                const height = img.height + lines.length * lineHeight + 40; // Увеличиваем высоту канваса в зависимости от количества строк
                canvasWithText.width = img.width;
                canvasWithText.height = height;

                // Рисуем белый фон
                ctx.fillStyle = "#ffffff";
                ctx.fillRect(0, 0, img.width, height);

                // Рисуем изображение QR-кода
                ctx.drawImage(img, 0, 0);

                // Рисуем текст под QR-кодом
                ctx.font = font;
                ctx.fillStyle = "#000000";
                ctx.textAlign = "center";

                let yOffset = img.height + 40; // Начальная позиция для текста
                lines.forEach((line) => {
                    ctx.fillText(line, img.width / 2, yOffset);
                    yOffset += lineHeight;
                });

                // Получаем URL изображения с текстом
                const finalImageUrl = canvasWithText.toDataURL("image/png");

                // Создаем ссылку для скачивания
                const link = document.createElement("a");
                link.href = finalImageUrl;
                link.download = `${boxName}_QR_Boxyfox.png`; // Имя файла
                link.click();
            };
        }
    };

    // Функция для печати QR-кода с подписью
    const printQRCode = () => {
        const canvas = qrRef.current.querySelector("canvas");
        const imageUrl = canvas.toDataURL("image/png");

        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
            const printWindow = window.open("", "", "width=600,height=600");
            printWindow.document.write(`<html><body><img src="${imageUrl}" /><p>${boxName}</p></body></html>`);
            printWindow.document.close();
            printWindow.print();
        };
    };

    return (
        <div className="container">
            <title>QR для {boxName}</title>
            <UserHeader />
            <div className="navigate">
                <div className="go-back">
                    <UI.Button type="secondary" icon="ic_24_arrow_left" onClick={() => navigate(-1)} className="back-btn" />
                    <h3>QR для {boxName}</h3>
                </div>
            </div>
            <div className="container">
                <div ref={qrRef}>
                <h4>{boxName}</h4>
                    {qrData ? (
                        <QRCodeCanvas value={qrData} size={256} />
                    ) : (
                        <p>Loading QR code...</p>
                    )}
                </div>

                <div className="buttons">
                    <UI.Button type="secondary" text="Напечатать QR" onClick={printQRCode} />
                    <UI.Button text="Сохранить QR код" onClick={saveQRCode} />
                </div>
            </div>
        </div>
    );
};

export default ShowBoxQR;
