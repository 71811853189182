import React from 'react';
import { Link } from 'react-router-dom';

const RulesPage = () => {
  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>Правила использования</h1>
        <p style={styles.subtitle}>Пожалуйста, ознакомьтесь с правилами нашего сервиса</p>
      </header>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>1. Основные положения</h2>
        <p>1.1. При использовании нашего сервиса вы соглашаетесь с нашими правилами и условиями.</p>
        <p>1.2. Мы оставляем за собой право изменять правила без предварительного уведомления. Пожалуйста, регулярно проверяйте эту страницу.</p>

        <h2 style={styles.sectionTitle}>2. Защита данных</h2>
        <p>2.1. Мы обязуемся защищать вашу личную информацию, включая данные, такие как электронная почта, пароль, IP-адрес и информация о браузере.</p>
        <p>2.2. Все данные шифруются и сохраняются в соответствии с актуальными стандартами безопасности.</p>

        <h2 style={styles.sectionTitle}>3. Доступ к сервису</h2>
        <p>3.1. Мы предоставляем вам доступ к сервису только при соблюдении этих правил.</p>
        <p>3.2. Вы обязуетесь не использовать наш сервис для незаконных действий, нарушающих права других пользователей.</p>

        <h2 style={styles.sectionTitle}>4. Информация о сессии</h2>
        <p>4.1. Мы сохраняем информацию о вашей сессии, включая IP-адрес, user-agent, геолокацию, временную зону и другие параметры, чтобы обеспечить безопасность и улучшить качество обслуживания.</p>
        <p>4.2. Все сессии могут быть проанализированы для обеспечения безопасности и выявления подозрительных действий.</p>

        <h2 style={styles.sectionTitle}>5. Правила авторизации</h2>
        <p>5.1. Для входа в систему используйте свою электронную почту и пароль. Пароль должен содержать не менее 8 символов и включать как буквы, так и цифры.</p>
        <p>5.2. Если вы забыли пароль, вы можете запросить восстановление доступа через электронную почту.</p>

        <h2 style={styles.sectionTitle}>6. Ответственность</h2>
        <p>6.1. Мы не несем ответственности за любые убытки или ущерб, вызванный использованием нашего сервиса, за исключением случаев, предусмотренных законом.</p>
        <p>6.2. Вы соглашаетесь возместить ущерб, если ваши действия приведут к нарушению законов или этих правил.</p>

        <h2 style={styles.sectionTitle}>7. Изменения в правилах</h2>
        <p>7.1. Мы можем в любой момент изменить эти правила, опубликовав обновленную версию на сайте.</p>
        <p>7.2. Продолжение использования сайта после внесения изменений означает ваше согласие с новыми правилами.</p>

        <h2 style={styles.sectionTitle}>8. Контакты</h2>
        <p>Если у вас есть вопросы по этим правилам, вы можете связаться с нами через раздел <Link to="/credentials" style={styles.link}>Контакты</Link>.</p>
      </section>

      <footer style={styles.footer}>
        <Link to="/" style={styles.button}>На главную</Link>
      </footer>
    </div>
  );
};

// Стилизация страницы
const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    color: '#333',
    margin: 0,
    padding: 0,
    textAlign: 'center',
  },
  header: {
    backgroundColor: '#ff6347',
    padding: '50px 0',
    color: 'white',
  },
  title: {
    fontSize: '3rem',
    margin: '0',
  },
  subtitle: {
    fontSize: '1.5rem',
    marginTop: '10px',
  },
  section: {
    padding: '50px 20px',
    backgroundColor: '#fff',
  },
  sectionTitle: {
    fontSize: '2rem',
    marginBottom: '20px',
  },
  link: {
    color: '#ff6347',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  footer: {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    fontSize: '0.9rem',
  },
  button: {
    padding: '15px 30px',
    backgroundColor: '#ffffff',
    color: '#ff6347',
    fontSize: '1.2rem',
    borderRadius: '5px',
    textDecoration: 'none',
  },
};

export default RulesPage;