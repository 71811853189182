import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_subscription"; // Correct import
import request from "../../utils/requests/requests";
import SubscriptionCard from "./subscriptionCard";
import UserHeader from "../../components/block/header/header";
import * as UI from "../../components/ui";

/**
 * A page for displaying the user's current subscription plan and available plans.
 * The page is accessible at /dashboard/subscription and shows the user's current plan,
 * the price of the current plan, and the available plans.
 * The user can upgrade to a different plan by clicking on the "Upgrade to" button.
 * The page also shows the unavailable plans.
 * The page is only accessible for logged-in users.
 * @returns {JSX.Element}
 */
const GetSubscription = () => {
    const navigate = useNavigate();
    const { spaceHash } = useParams();

    const [subscriptionData, setSubscriptionData] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    // Function to fetch subscription data
    useEffect(() => {
        const fetchSubscriptionData = async () => {
            setLoading(true);
            setError("");

            try {
                // Make a request to fetch subscription data
                const data = await request("GET", USER_ENDPOINTS.GET_MY_SUBSCRIPTION, false, true);
                
                if (data.status === "success") {
                    setSubscriptionData(data);
                } else {
                    setError(data.message || "Failed to load subscription data");
                }
            } catch (err) {
                setError("An error occurred, please try again later");
                console.error("Error:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptionData();
    }, []);

    // Function to send a request for upgrading the plan
    const handleUpgradePlan = async (planAlias) => {
        setLoading(true);
        setError(""); // Clear any previous errors

        try {
            // Send a request to proceed with the payment for upgrading
            const response = await request("GET", USER_ENDPOINTS.GET_PROCEED_PAYMENT(planAlias), false, true);

            if (response.status === "success" && response.confirmationUrl) {
                // If the response is successful, redirect to the confirmation URL
                window.location.href = response.confirmationUrl;
            } else {
                setError(response.message || "Failed to upgrade plan");
            }
        } catch (err) {
            setError("An error occurred, please try again later");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    // Functions for buttons
    const handlePaymentHistory = () => {
        navigate("/payment-history");
    };

    // Set page title when subscription data is available
    useEffect(() => {
        if (subscriptionData) {
            document.title = "Мой тариф";
        } else {
            document.title = "🤔";
        }
    }, [subscriptionData]);

    return (
        <div className="container">
            <UserHeader />
            {error &&  <UI.ToastNotification status="error" message={error} />}
            <div className="navigate">
                <div className="go-back">
                    <UI.Button type="secondary" icon="ic_24_arrow_left" to="/dashboard" className="back-btn" />
                    <h3>Мой тариф</h3>
                </div>
                {/* <div className="actions">
                    <UI.Button icon="ic_24_coupon" type="secondary" to="/dashboard/subscription/usePromocode" className="payment-btn" />
                    <UI.Button icon="ic_24_bill" type="secondary" to="/dashboard/subscription/history" className="payment-btn" />
                    <UI.Button icon="ic_24_card" type="secondary" to="/dashboard/subscription/paymentMethods" className="payment-btn" />
                </div> */}
            </div>
            
            {loading && <div className="loading">🤔</div>}
            {subscriptionData && (
                <div className="subscription-cards">
                    <SubscriptionCard className="current" subscriptionData={subscriptionData} handlePaymentHistory={handlePaymentHistory} handleUpgradePlan={handleUpgradePlan} />

                        {subscriptionData.available_tiers.length > 0 && (
                            subscriptionData.available_tiers.map((avtier) => (
                                <SubscriptionCard key={avtier.alias} className="available" subscriptionData={avtier} handlePaymentHistory={handlePaymentHistory} handleUpgradePlan={handleUpgradePlan} />
                            ))
                        )}
                        {subscriptionData.unavailable_tiers.length > 0 && (
                            subscriptionData.unavailable_tiers.map((untier) => (
                                <SubscriptionCard key={untier.alias} className="unavailable" subscriptionData={untier} handlePaymentHistory={handlePaymentHistory} handleUpgradePlan={handleUpgradePlan} />
                            ))
                        )}
                   
                </div>
            )}
        </div>
    );
};

export default GetSubscription;