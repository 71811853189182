// src/pages/viewer/GetBoxesViewer.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Для работы с параметрами маршрута
import { ENDPOINTS as USER_ENDPOINTS } from "../../utils/requests/e_spaces";
import request from "../../utils/requests/requests"; // Для выполнения запросов

import * as UI from "../../components/ui";

/**
 * GetBoxesViewer - component for displaying a list of boxes and unboxed items in a given space
 *
 * Component receives spaceHash as a parameter and fetches data about the space and its boxes and items
 * It displays the following information:
 *  - Space name and creation date
 *  - List of boxes with links to the box viewer
 *  - List of unboxed items with links to the item viewer
 *
 * @param {string} spaceHash - hash of the space to display data for
 * @returns {React.ReactElement} - a React component displaying the space data
 */
const GetBoxesViewer = () => {
    const { spaceHash,boxHash } = useParams(); // Получаем spaceHash из параметров маршрута
    const [spaceData, setSpaceData] = useState(null);
    const [boxes, setBoxes] = useState([]); // Для хранения данных о коробках
    const [items, setItems] = useState([]); // Для хранения данных о "unboxed" элементах
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSpaceData = async () => {
            setLoading(true);
            setError("");

            try {
                // Выполняем запрос для получения данных о конкретном пространстве
                const data = await request("GET", USER_ENDPOINTS.GET_BOXES_VIEWER(spaceHash), false, true); // Запрос с Bearer token
                if (data.status === "success") {
                    setSpaceData(data); // Если запрос успешен, сохраняем данные о пространстве

                    // Проверка на наличие поля 'boxes'
                    if (data && Array.isArray(data.boxes)) {
                        setBoxes(data.boxes); // Сохраняем данные о коробках
                    } else {
                        setError("No boxes found in this space.");
                    }

                    // Фильтруем элементы, у которых нет box_hash (unboxed)
                    if (Array.isArray(data.items)) {
                        const unboxedItems = data.items.filter(item => item.box_hash === null);
                        setItems(unboxedItems); // Сохраняем данные о элементах без коробки
                    }
                } else {
                    setError(data.message || "Failed to load space");
                }
            } catch (err) {
                setError("An error occurred, please try again later");
                console.error("Error:", err); // Логирование для отладки
            } finally {
                setLoading(false);
            }
        };

        fetchSpaceData();
    }, [spaceHash]); // Срабатывает при изменении spaceHash

    const handleItemClick = (item) => {
        if (!item.box_hash) {
            alert("This item has no box_hash, nothing will be done.");
        } else {
            // Продолжаем переход по ссылке, если box_hash есть
            window.location.href = `/item/${item.item_hash}`;
        }
    };

    return (
        <div className="container">
            {loading && <div className="loading">Loading...</div>}
            {error && <div className="error">{error}</div>}

            {/* Отображаем данные о пространстве */}
            {spaceData && (
                <div className="space-info">
                    <h1>{spaceData.space_name}</h1>
                    <p>Created at: {spaceData.created_at}</p>
                </div>
            )}

            {/* Отображаем список коробок, если они есть */}
            {boxes.length > 0 ? (
                <div className="boxes-list">
                    <h2>Boxes in this space:</h2>
                    <ul>
                        {boxes.map((box) => (
                            <li key={box.box_hash} className="box-item">
                                <a href={`/viewer/${spaceHash}/${box.box_hash}`} className="box-link">
                                    {box.name} - {box.description}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <div className="no-boxes">No boxes found</div>
            )}

            {/* Отображаем элементы без коробки (Unboxed) */}
            {items.length > 0 ? (
                <div className="unboxed-items">
                    <h2>Unboxed Items:</h2>
                    <ul>
                        {items.map((item) => (
                            <li key={item.item_hash} className="item">
                                <a
                                    href="#"
                                    className="item-link"
                                    onClick={(e) => {
                                        e.preventDefault(); // Предотвращаем переход по ссылке
                                        handleItemClick(item); // Вызываем обработчик клика
                                    }}
                                >
                                    <p>{item.name}</p>
                                    <p>{item.description}</p>
                                    <img src={`https://boxyfox.ru/uploads/${item.photo_url}`} alt={item.name} className="item-img" />
                                    <p>Created at: {item.created_at}</p>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <div className="no-unboxed-items">No unboxed items found</div>
            )}
        </div>
    );
};

export default GetBoxesViewer;