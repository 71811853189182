// src/utils/requests/requests.js
import BASE_URL from "../constants/baseUrl"; // Импортируем базовый URL

// Универсальная функция для отправки запросов
const request = async (method, endpoint, body = null, isJson = false, withCredentials = false) => {
    const url = `${BASE_URL}${endpoint}`;

    // Устанавливаем заголовки
    const headers = {
        "Authorization": localStorage.getItem("session_token")
            ? `Bearer ${localStorage.getItem("session_token")}`
            : "",
    };

    let bodyData;

    // По умолчанию используем FormData
    if (body) {
        if (isJson) {
            headers["Content-Type"] = "application/json"; // Устанавливаем заголовок для JSON
            bodyData = JSON.stringify(body); // Конвертируем тело запроса в JSON
        } else if (body instanceof FormData) {
            bodyData = body; // Если уже передан FormData, используем его
        } else {
            bodyData = new FormData(); // Создаем FormData
            for (const key in body) {
                bodyData.append(key, body[key]); // Добавляем все ключи из объекта
            }
        }
    }

    const options = {
        method,
        headers,
        credentials: withCredentials ? "include" : "same-origin", // Добавляем куки, если с флагом true
    };

    if (bodyData) {
        options.body = bodyData;
    }

    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Request failed:", error.message);
        throw error;
    }
};

export default request;