import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const ToastNotification = ({ status, message, hasCloseButton }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
        const timer = setTimeout(() => {
            setVisible(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`toast ${status} ${visible ? "show" : "hide"}`}>
            <span>{message}</span>
            {hasCloseButton && (
                <button className="toast__close" onClick={() => setVisible(false)}>
                    ✖
                </button>
            )}
        </div>
    );
};

ToastNotification.propTypes = {
    status: PropTypes.oneOf(["success", "error"]).isRequired,
    message: PropTypes.string.isRequired,
    hasCloseButton: PropTypes.bool,
};

ToastNotification.defaultProps = {
    hasCloseButton: false,
};

export default ToastNotification;